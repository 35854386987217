import React from 'react'
import { Button, Form, Input, message, Modal, PageHeader } from 'antd'
import { UserStore } from '~/store/UserStore';
import { User } from 'digitran-core/lib/Models';

import '../../styles/transparentModal.css'
import '../../styles/input.css'
import { InputMask } from '../../components/InputMask';

export class UserModal extends React.Component {

    state = {
        infoChanged: false,
        user: {
            name: {
                value: null,
                validateStatus: null,
                errorMsg: null
            },
            document: {
                value: null,
                validateStatus: null,
                errorMsg: null
            },
            password: {
                value: null,
                validateStatus: null,
                errorMsg: null
            },
            confirmPassword: {
                value: null,
                validateStatus: null,
                errorMsg: null
            }
        }
    }

    formRef = null;

    constructor(props) {
        super(props);
        this.formRef = React.createRef();
    }

    async componentDidMount() {
        this.setState({
            user: {
                name: {
                    value: null,
                    validateStatus: null,
                    errorMsg: null
                },
                document: {
                    value: null,
                    validateStatus: null,
                    errorMsg: null
                },
                password: {
                    value: null,
                    validateStatus: null,
                    errorMsg: null
                },
                confirmPassword: {
                    value: null,
                    validateStatus: null,
                    errorMsg: null
                }
            }
        });
    }

    async componentDidUpdate(prevProps) {
        if (!prevProps.visible && this.props.visible) {
            const userData = await UserStore.account(true) || {};
            if (userData && userData.id) {
                await this.setField('name', userData.name);
                await this.setField('document', userData.document);

                this.formRef.current && this.formRef.current.setFieldsValue({
                    name: userData.name,
                    document: userData.document,
                    password: null,
                    confirmPassword: null,
                });
            }
        }
    }

    async saveInfo() {
        try {

            const { user: userState } = this.state;
            const response = await UserStore.updateAccount({
                name: userState.name.value,
                document: userState.document.value,
                password: userState.password.value,
                confirmPassword: userState.confirmPassword.value
            });

            if (!!response.success) {
                this.setState({ infoChanged: false });
                message.success("Dados do usuário salvo com sucesso!", 1);
            } else {
                message.warning(response.error, 1);
            }

        } catch (err) {
            console.log(err);
        }
    }

    validateUserSection(field, value) {
        const isValid = User.validate(field, value);

        switch (field) {
            case 'name':
                return {
                    value,
                    validateStatus: isValid ? null : "error",
                    errorMsg: isValid ? null : "Nome inválido"
                }
            case 'document':
                return {
                    value,
                    validateStatus: isValid ? null : "error",
                    errorMsg: isValid ? null : "Documento inválido"
                }
            case 'password':
                return {
                    value,
                    validateStatus: isValid ? null : "error",
                    errorMsg: isValid ? null : "Senha inválida"
                }
            case 'confirmPassword':
                const userTmp = new User();
                userTmp.password = this.state.user.password.value;
                const isConfirmValid = userTmp.validate('confirmPassword', value);

                return {
                    value,
                    validateStatus: isConfirmValid ? null : "error",
                    errorMsg: isConfirmValid ? null : "As senhas não conferem"
                }
        }
    }

    async setField(field, value) {
        const { user } = this.state;

        this.setState({
            infoChanged: true,
            user: {
                ...user,
                [field]: this.validateUserSection(field, value)
            }
        })

    }

    renderErrorMessage(errorMsg) {
        return (
            errorMsg && <span style={{ color: 'white', fontSize: 10, paddingLeft: 5 }}>
                {errorMsg}
            </span>
        )
    }

    render() {
        const { visible, onClose } = this.props;
        const { user, infoChanged } = this.state;



        return (
            <Modal visible={visible} onCancel={onClose} centered style={{ backgroundColor: 'transparent' }} header={null} footer={null} bodyStyle={{ display: 'flex', flexDirection: 'column', background: 'linear-gradient(135deg, rgba(0,199,73,1) 0%, rgba(0,243,63,1) 100%)', borderRadius: 15, minHeight: '90vh' }}>
                <PageHeader title={<span style={{ color: "white" }}>Dados do usuário</span>} style={{ paddingLeft: 0 }} />

                <div style={{ display: 'flex', flexDirection: 'column', marginTop: 30, flex: 1 }}>
                    <Form ref={this.formRef} layout="vertical" style={{ flex: 1, display: 'flex', flexDirection: 'column' }} onValuesChange={
                        (changedValues) => {
                            Object.entries(changedValues).forEach((field) => {
                                if (field[0] == 'document') {
                                    field[1] = field[1].replace(/[^0-9]/g, "");
                                }

                                this.setField(field[0], field[1]);
                            });
                        }
                    }>
                        <Form.Item hasFeedback name="name" validateStatus={user.name.validateStatus} help={this.renderErrorMessage(user.name.errorMsg)}>
                            <Input placeholder="Nome completo/Razão Social" className="input" style={{ borderRadius: 50 }} />
                        </Form.Item>
                        <Form.Item hasFeedback name="document" validateStatus={user.document.validateStatus} help={this.renderErrorMessage(user.document.errorMsg)}>
                            <InputMask
                                alwaysShowMask={false}
                                mask={`${user.document.value || ''}`.replace(/[^0-9]/g, "").length <= 11 ? "999.999.999-999" : "99.999.999/9999-99"}
                                placeholder="CPF/CNPJ"
                                className="input"
                                maskChar={" "}
                                style={{ borderRadius: 50 }}
                            />
                        </Form.Item>
                        <Form.Item hasFeedback name="senha" validateStatus={user.password.validateStatus} help={this.renderErrorMessage(user.password.errorMsg)}>
                            <Input.Password placeholder="Senha" className="input" style={{ borderRadius: 50 }} />
                        </Form.Item>
                        <Form.Item hasFeedback name="confirmPassword" validateStatus={user.confirmPassword.validateStatus} help={this.renderErrorMessage(user.confirmPassword.errorMsg)}>
                            <Input.Password placeholder="Confirme a sua senha" className="input" style={{ borderRadius: 50 }} />
                        </Form.Item>
                        {
                            infoChanged && (
                                <Form.Item className={'flexEndInputControl'} style={{ flex: 1, justifyContent: 'flex-end' }}>
                                    <Button className="button-secondary" shape="round" style={{ border: 'none' }} onClick={() => this.saveInfo()}> SALVAR </Button>
                                </Form.Item>
                            )
                        }
                    </Form>
                </div>
            </Modal>
        )
    }
}
