import Axios from 'axios'
import { Request } from "../core/http/Request";
import { Response } from '../core/http/Response';
import { Address } from '../core/models';
import SecureLS from 'secure-ls';

const store = new SecureLS();

export class UserStore {
    static isLogged() {
        return !!UserStore.getUserToken();
    }

    static setUserToken(token) {
        store.set('userToken', token);  
    }

    static getUserToken() {
        return store.get('userToken');
    }

    static async login(email, password) {
        const response = await Request.post('login', { email, password });

        if (response.success) {
            UserStore.setUserToken(response.data.token)
        }

        return response;
    }

    static clearStore() {
        store.removeAll();
    }

    static async account(reset = false) {
        const user = await store.get('account');

        if (!user || reset) {
            const userToken = await UserStore.getUserToken();
            const response = await Request.get('account', { headers: { 'x-user-token': userToken } });

            if (!response.success) {
                return null;
            }

            await store.set('account', response.data);
            return response.data;
        }

        return user;
    }

    static async getUserAddress() {
        const account = await UserStore.account(true);
        return account ? account.address || {} : {}
    }

    static async setUserAddress(addressData) {
        try {
            const address = Address.fromObject({
                ...addressData,
                uf: addressData.uf || addressData.state
            });

            if (!address.validate('cep')) {
                return new Response(null, "CEP inválido!");
            }

            if (!address.validate('address')) {
                return new Response(null, "Endereço inválido!");
            }

            if (!address.validate('number')) {
                return new Response(null, "Número inválido!");
            }

            if (!address.validate('neighborhood')) {
                return new Response(null, "Bairro inválido!");
            }

            if (!address.validate('city')) {
                return new Response(null, "Cidade inválida!");
            }

            if (!address.validate('state')) {
                return new Response(null, "Estado inválido!");
            }

            const userToken = await UserStore.getUserToken();
            const addressResponse = await Request.put('/account', { address }, { headers: { 'x-user-token': userToken } });
            if (!addressResponse.success) {
                return addressResponse;
            }

            await UserStore.account(true);
            return new Response(address);
        } catch (err) {
            console.log(err);
            return new Response(null, "Ocorreu um erro ao salvar o endereço!");
        }
    }

    static async vehicle(vehicleId) {
        try {
            const userToken = await UserStore.getUserToken();
            return await Request.get(`/vehicles/${vehicleId}`, { headers: { 'x-user-token': userToken } });
        } catch (err) {
            console.log(err);
            return new Response(null, "Ocorreu um erro o veículo!");
        }
    }


    static async listVehicles() {
        try {
            const userToken = await UserStore.getUserToken();
            const response = await Request.get(`/vehicles`, { headers: { 'x-user-token': userToken } });
            
            if (!response.success) {
                return []
            }

            return response.data;

        } catch (err) {
            console.log(JSON.stringify(err, null, 2));
            throw new Error("Ocorreu um erro ao carrega a lista de veículos!");
        }
    }

    static async addVehicle(vehicle) {
        try {
            const userToken = UserStore.getUserToken();
            return await Request.post('/vehicles', vehicle, { headers: { 'x-user-token': userToken } });
        } catch (err) {
            console.log(err);
            return new Response(null, "Ocorreu um erro ao adicionar o veículo!");
        }
    }

    static async removeVehicle(vehicleId) {
        try {
            const userToken = await UserStore.getUserToken();
            return await Request.delete(`/vehicles/${vehicleId}`, { headers: { 'x-user-token': userToken } });
        } catch (err) {
            console.log(err);
            return new Response(null, "Ocorreu um erro ao excluir o veículo!");
        }
    }

    static async listPayments(renavam) {
        try {
            const userToken = await UserStore.getUserToken();
            return await Request.get(`/payments/renavam/${renavam}`, { headers: { 'x-user-token': userToken } });
        } catch (err) {
            console.log(err);
            return new Response(null, "Ocorreu um erro ao carregar as transações!");
        }
    }
    
    static async searchVehicle(renavam) {
        try {
            return await Request.get(`/search/${renavam}`);
        } catch (err) {
            console.log(err);
            return new Response(null, "Ocorreu um erro ao buscar o veículo!");
        }
    }

    static async searchCEP(cep) {
        try {

            const validacep = /^[0-9]{8}$/;
            if (!validacep.test(cep)) {
                return null;
            }

            const responseCep = await Axios.get(`https://viacep.com.br/ws/${cep}/json`);
            return responseCep.data;
        } catch (err) {
            console.log(err);
            return null;
        }
    }

    static async recoverPassword(email) {
        try {
            const response = await Request.get(`/recover-password/${email}`);
            return response.success;
        } catch (err) {
            console.log(JSON.stringify(err, null, 2));
            throw new Error("Ocorreu um erro ao recuperar a senha!");
        }
    }

    static async getSelectedRenavam() {
        return await store.get('selectedRenavam');
    }

    static async setSelectedRenavam(renavam, setVehicle = true) {
        const response = await UserStore.searchVehicle(renavam);    
        if(response.error) {
            throw new Error(response.error);
        }
        
        await store.set('selectedRenavam', renavam);
        const vehicle = response.success ? response.data : null;
        
        if(!vehicle) {
            throw new Error("Veículo não encontrado!");
        }

        if(setVehicle) {
            await UserStore.setSelectedVehicle(vehicle);
        }
        
        return vehicle;
    }

    static async setSelectedVehicle(vehicle) {
        await store.set('selectedVehicle', vehicle);
    }

    static async getSelectedVehicle() {
        return await store.get('selectedVehicle');
    }

    static async simulateParcels(value) {
        try {
            const response = await Request.get(`/parcel/simulation/${value}`);
            if (!response.success) {
                return null;
            }
            return response.data;
        } catch(err) {
            return null;
        }        
    }

    static async listPayments(renavam) {
        try {
            const userToken = await UserStore.getUserToken();
            return await Request.get(`/payments/renavam/${renavam}`, { headers: { 'x-user-token': userToken } });
        } catch (err) {
            console.log(err);
            return new Response(null, "Ocorreu um erro ao carregar as transações!");
        }
    }

    static async updateAccount({ name, document, password, confirmPassword }) {
        try {

            if (!name) {
                return new Response(null, "Por favor, insira o seu nome!");
            }

            if (!document) {
                return new Response(null, "Por favor, insira o seu documento!");
            }

            if (!!password) {
                if (`${password || ''}`.length < 8) {
                    return new Response(null, "A senha deve contem no mínimo 8 caracteres!");
                }

                if (!confirmPassword || password != confirmPassword) {
                    return new Response(null, "As senhas não são iguais!");
                }
            }

            const userToken = UserStore.getUserToken();
            return await Request.put('/account', { name, document, password, confirmPassword }, { headers: { 'x-user-token': userToken } });

        } catch (err) {
            console.log(err);
            return new Response(null, "Ocorreu um erro ao cadastrar o usuário!");
        }
    }

    static async getUserAddress() {
        const account = await UserStore.account(true);
        return account ? account.address || {} : {}
    }

    static async setUserAddress(addressData) {
        try {
            const address = Address.fromObject({
                ...addressData,
                uf: addressData.uf || addressData.state
            });

            if (!address.validate('cep')) {
                return new Response(null, "CEP inválido!");
            }

            if (!address.validate('address')) {
                return new Response(null, "Endereço inválido!");
            }

            if (!address.validate('number')) {
                return new Response(null, "Número inválido!");
            }

            if (!address.validate('neighborhood')) {
                return new Response(null, "Bairro inválido!");
            }

            if (!address.validate('city')) {
                return new Response(null, "Cidade inválida!");
            }

            if (!address.validate('state')) {
                return new Response(null, "Estado inválido!");
            }

            const userToken = await UserStore.getUserToken();
            const addressResponse = await Request.put('/account', { address }, { headers: { 'x-user-token': userToken } });
            if (!addressResponse.success) {
                return addressResponse;
            }

            await UserStore.account(true);
            return new Response(address);
        } catch (err) {
            console.log(err);
            return new Response(null, "Ocorreu um erro ao salvar o endereço!");
        }
    }

    static async register(user, address) {
        try {

            if (!user.name || !user.validate("name")) {
                return new Response(null, "Por favor, insira o seu nome!");
            }

            if (!user.document) {
                return new Response(null, "Por favor, insira o numero do seu documento!");
            } else {
                user.document = user.document.replace(/[^0-9]/g, "");
                if (!user.validate("document")) {
                    return new Response(null, "Documento inválido!");
                }
            }

            if (!user.email || !user.validate("email")) {
                return new Response(null, "Por favor, insira o seu e-mail!");
            }

            if (!user.password || !user.validate("password")) {
                return new Response(null, "A senha deve contem no mínimo 8 caracteres!");
            }

            if (!address.validate('cep')) {
                return new Response(null, "CEP inválido!");
            }

            if (!address.validate('address')) {
                return new Response(null, "Endereço inválido!");
            }

            if (!address.validate('number')) {
                return new Response(null, "Número do endereço inválido!");
            }

            if (!address.validate('neighborhood')) {
                return new Response(null, "Bairro inválido!");
            }

            if (!address.validate('city')) {
                return new Response(null, "Cidade inválida!");
            }

            if (!address.validate('state')) {
                return new Response(null, "Estado inválido!");
            }

            return await Request.post('/register', {
                ...user,
                address
            });

        } catch (err) {
            console.log(err);
            return new Response(null, "Ocorreu um erro ao cadastrar o usuário!");
        }
    }

    static async transactionDetails(transactionId) {
        try {
            const userToken = await UserStore.getUserToken();
            return await Request.get(`/payments/${transactionId}`, { headers: { 'x-user-token': userToken } });
        } catch (err) {
            console.log(err);
            return new Response(null, "Ocorreu um erro ao carregar a transação!");
        }
    }
}