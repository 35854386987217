import React from 'react'
import { Button, Form, Input, message, Modal, PageHeader } from 'antd'
import { UserStore } from '~/store/UserStore';
import { Address, User } from '../../core/models';
import { MaskedInput } from 'antd-mask-input';

import '../../styles/transparentModal.css'
import '../../styles/input.css'

export class AddressModal extends React.Component {

    state = {
        infoChanged: false,
        address: {}
    }

    formRef = null;

    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.state.address = this.getInitState();
    }

    getInitState(defaultObj = null) {
        const fields = ['cep', 'address', 'number', 'complement', 'neighborhood', 'city', 'state'];
        return fields.reduce((o, field) => ({ ...o, [field]: { value: (!!defaultObj && typeof defaultObj == 'object') ? (defaultObj[field] || null) : null, validateStatus: null, errorMsg: null } }), {});
    }


    async componentDidUpdate(prevProps) {
        if (!prevProps.visible && this.props.visible) {
            const addressData = await UserStore.getUserAddress() || {};
            this.setState({ address: this.getInitState(addressData) }, () => {
                this.formRef.current && this.formRef.current.setFieldsValue({
                    cep: addressData.cep,
                    address: addressData.address,
                    number: addressData.number,
                    complement: addressData.complement,
                    neighborhood: addressData.neighborhood,
                    city: addressData.city,
                    state: addressData.state
                });
            })
        }
    }

    async searchCep(cep) {
        try {
            const { address } = this.state;
            if (cep) {
                const findedAddress = await Address.searchAddressByCEP(cep);
                if (findedAddress) {
                    const newAddress = {
                        ...address
                    }

                    Object.entries({ ...findedAddress, complement: null, number: null }).forEach((entry) => {
                        newAddress[entry[0]] = this.validateAddressSection(entry[0], entry[1]);
                    })

                    this.setState({ address: newAddress }, () => {
                        this.formRef.current.setFieldsValue({
                            cep: cep,
                            address: newAddress.address.value,
                            number: null,
                            complement: null,
                            neighborhood: newAddress.neighborhood.value,
                            city: newAddress.city.value,
                            state: newAddress.state.value
                        })
                    });
                } else {
                    this.setState({
                        address: {
                            cep: this.validateAddressSection('cep', cep),
                            address: this.validateAddressSection('address', null),
                            number: this.validateAddressSection('number', null),
                            complement: this.validateAddressSection('complement', null),
                            neighborhood: this.validateAddressSection('neighborhood', null),
                            city: this.validateAddressSection('city', null),
                            state: this.validateAddressSection('state', null)
                        }
                    }, () => {
                        this.formRef.current.setFieldsValue({
                            cep: cep,
                            address: null,
                            number: null,
                            complement: null,
                            neighborhood: null,
                            city: null,
                            state: null
                        })
                    });
                }
            }
        } catch (err) {
            console.log(err);
        }
    }

    async saveInfo() {
        try {

            const { address: addressState } = this.state;

            const response = await UserStore.setUserAddress({
                cep: addressState.cep.value,
                address: addressState.address.value,
                number: addressState.number.value,
                complement: addressState.complement.value,
                neighborhood: addressState.neighborhood.value,
                city: addressState.city.value,
                state: addressState.state.value
            });

            console.log(response);

            if (!!response.success) {
                this.setState({ infoChanged: false });
                message.success("Endereço salvo com sucesso!", 1);
            } else {
                message.warning(response.error, 1);
            }

        } catch (err) {
            console.log(err);
        }
    }

    validateAddressSection(field, value) {
        const isValid = Address.validate(field, value);

        switch (field) {
            case 'cep':
                return {
                    value,
                    validateStatus: isValid ? null : "error",
                    errorMsg: isValid ? null : "CEP inválido"
                }
            case 'address':
                return {
                    value,
                    validateStatus: isValid ? null : "error",
                    errorMsg: isValid ? null : "Endereço inválido"
                }
            case 'number':
                return {
                    value,
                    validateStatus: isValid ? null : "error",
                    errorMsg: isValid ? null : "Número inválido"
                }
            case 'neighborhood':
                return {
                    value,
                    validateStatus: isValid ? null : "error",
                    errorMsg: isValid ? null : "Bairro inválido"
                }
            case 'city':
                return {
                    value,
                    validateStatus: isValid ? null : "error",
                    errorMsg: isValid ? null : "Cidade inválida"
                }
            case 'state':
                return {
                    value,
                    validateStatus: isValid ? null : "error",
                    errorMsg: isValid ? null : "Estado inválido"
                }
            case 'complement':
                return {
                    value,
                    validateStatus: null,
                    errorMsg: null
                }
        }
    }

    async setField(field, value, callback) {
        const { address } = this.state;

        this.setState({
            infoChanged: true,
            address: {
                ...address,
                [field]: this.validateAddressSection(field, value)
            }
        }, callback)
    }

    renderErrorMessage(errorMsg) {
        return (
            errorMsg && <span style={{ color: 'white', fontSize: 10, paddingLeft: 5 }}>
                {errorMsg}
            </span>
        )
    }

    render() {
        const { visible, onClose } = this.props;
        const { address, infoChanged } = this.state;

        return (
            <Modal visible={visible} onCancel={onClose} centered style={{ backgroundColor: 'transparent' }} header={null} footer={null} bodyStyle={{ display: 'flex', flexDirection: 'column', background: 'linear-gradient(135deg, rgba(0,199,73,1) 0%, rgba(0,243,63,1) 100%)', borderRadius: 15, minHeight: '90vh' }}>
                <PageHeader title={<span style={{ color: "white" }}>Endereço</span>} style={{ paddingLeft: 0 }} />

                <div style={{ display: 'flex', flexDirection: 'column', marginTop: 30, flex: 1 }}>
                    <Form ref={this.formRef} layout="vertical" style={{ flex: 1, display: 'flex', flexDirection: 'column' }} onValuesChange={
                        (changedValues) => {
                            Object.entries(changedValues).map((field) => {
                                this.setField(field[0], field[1], () => {
                                    if (field[0] == 'cep' && field[1].length >= 8) {
                                        this.searchCep(field[1].replace(/[^0-9]/g, ""));
                                    }
                                });
                            });
                        }
                    }>
                        <Form.Item hasFeedback name="cep" validateStatus={address.cep.validateStatus} help={this.renderErrorMessage(address.cep.errorMsg)}>
                            <MaskedInput mask="00.000-000" placeholder="CEP" className="input" style={{ borderRadius: 50 }} />
                        </Form.Item>
                        <Form.Item hasFeedback name="address" validateStatus={address.address.validateStatus} help={this.renderErrorMessage(address.address.errorMsg)}>
                            <Input placeholder="Endereço" className="input" style={{ borderRadius: 50 }} readOnly />
                        </Form.Item>
                        <Form.Item hasFeedback name="number" validateStatus={address.number.validateStatus} help={this.renderErrorMessage(address.number.errorMsg)}>
                            <Input placeholder="Número" className="input" style={{ borderRadius: 50 }} />
                        </Form.Item>
                        <Form.Item hasFeedback name="complement" validateStatus={address.complement.validateStatus} help={this.renderErrorMessage(address.complement.errorMsg)}>
                            <Input placeholder="Complemento" className="input" style={{ borderRadius: 50 }} />
                        </Form.Item>
                        <Form.Item hasFeedback name="neighborhood" validateStatus={address.neighborhood.validateStatus} help={this.renderErrorMessage(address.neighborhood.errorMsg)}>
                            <Input placeholder="Bairro" className="input" style={{ borderRadius: 50 }} readOnly />
                        </Form.Item>
                        <Form.Item hasFeedback name="city" validateStatus={address.city.validateStatus} help={this.renderErrorMessage(address.city.errorMsg)}>
                            <Input placeholder="Cidade" className="input" style={{ borderRadius: 50 }} readOnly />
                        </Form.Item>
                        <Form.Item hasFeedback name="state" validateStatus={address.state.validateStatus} help={this.renderErrorMessage(address.state.errorMsg)}>
                            <Input placeholder="Estado" className="input" style={{ borderRadius: 50 }} readOnly />
                        </Form.Item>
                        {
                            infoChanged && (
                                <Form.Item className={'flexEndInputControl'} style={{ flex: 1, justifyContent: 'flex-end' }}>
                                    <Button className="button-secondary" shape="round" style={{ border: 'none' }} onClick={() => this.saveInfo()}> SALVAR </Button>
                                </Form.Item>
                            )
                        }
                    </Form>
                </div>
            </Modal>
        )
    }
}
