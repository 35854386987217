import { Request } from '../core/http/Request';
import { Response } from '../core/http/Response';
import { UserStore } from './UserStore';
import { CardStore } from './CardStore';
import SecureLS from 'secure-ls';

const store = new SecureLS();


export class CartStore {

    static async getCartItems() {
        return (await store.get('cartItems')) || [];
    }

    static async setCartItems(debits) {
        await store.set('cartItems', debits);
    }

    static async simulateParcels(value) {
        try {
            const response = await Request.get(`/parcel/simulation/${value}`);
            if (!response.success) {
                return null;
            }
            return response.data;
        } catch (err) {
            return null;
        }
    }

    static async checkout({ renavam, debits, parcels }) {
        try {
            if (!UserStore.isLogged()) {
                return new Response(null, "Para efetivar a operação o usuário deve estar logado!");
            }

            const [userToken, activeCard] = await Promise.all([
                UserStore.getUserToken(),
                CardStore.getActiveCard()
            ]);

            if (!activeCard) {
                return new Response(null, "É necessário cadastrar um cartão de crédito para efetivar a transação!");
            }

            //Realizar a requisição de checkout
            return await Request.post(
                `/checkout`,
                { renavam, debits, parcels, card: activeCard },
                { headers: { 'x-user-token': userToken } }
            );

        } catch (err) {
            console.log(err)
            return new Response(null, "Ocorreu um erro durante a transação!");
        }
    }
}