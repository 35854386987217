import Axios from "axios";

export class Request {

    static baseUrl = null;

    static init(baseUrl) {
        this.baseUrl = baseUrl;
    }

    static async get(endpoint, options = {}) {
        const response = await Axios({
            ...options,
            url: `${this.baseUrl}/${endpoint}`,
            method: 'GET'
        });

        return await response.data;

    }

    static async post(endpoint, data, options = {}) {
        const response = await Axios({
            ...options,
            url: `${this.baseUrl}/${endpoint}`,
            method: 'POST',
            data
        });
        console.log(response);
        return await response.data;
    }
    
    static async put(endpoint, data, options = {}) {
        const response = await Axios({
            ...options,
            url: `${this.baseUrl}/${endpoint}`,
            method: 'PUT',
            data
        });

        return await response.data;
    }
    
    static async delete(endpoint, options = {}) {
        const response = await Axios({
            ...options,
            url: `${this.baseUrl}/${endpoint}`,
            method: 'DELETE'
        });

        return await response.data;
    }
}