import React from 'react'
import { Modal, PageHeader, Row } from 'antd'
import digitranLogo from '../../assets/logo.svg'
import '../../styles/transparentModal.css'

export const AboutModal = ({ visible, onClose }) => {
    return (
        <Modal visible={visible} onCancel={onClose} centered style={{ backgroundColor: 'transparent' }} header={null} footer={null} bodyStyle={{ display: 'flex', flexDirection: 'column', background: 'linear-gradient(135deg, rgba(0,199,73,1) 0%, rgba(0,243,63,1) 100%)', borderRadius: 15, minHeight: '90vh' }}>
            <PageHeader title={<span style={{ color: "white" }}>Sobre</span>} style={{ paddingLeft: 0 }} />
            <div style={{ display: 'flex', flexDirection: 'column', marginTop: 30, flex: 1 }}>
                <Row style={{ paddingLeft: 15, paddingRight: 15, justifyContent: 'center' }}>
                    <img src={digitranLogo} width={"80%"} style={{ marginBottom: 40 }} />
                </Row>
                <div style={{ flex: 1 }}>
                    <p style={{ color: 'white', marginBottom: 10, fontSize: 16, marginBottom: 0 }}>
                        O Digitran é uma nova plataforma para te ajudar a manter os débitos e encargos do seu veículo em dia.
                    </p>
                    <p style={{ color: 'white', marginBottom: 10, fontSize: 16, marginBottom: 0 }}>
                        Em poucos passos, você consulta a relação de débitos do seu renavam, seleciona apenas os débitos que deseja pagar no momento e com o parcelamento que vai até 24x.
                    </p>
                    <p style={{ color: 'white', marginBottom: 10, fontSize: 16, marginBottom: 0 }}>
                        Tudo em uma plataforma segura, ágil e fácil de usar.
                    </p>
                </div>
                <div style={{ marginTop: 30 }}>
                    <p style={{ color: 'white', fontWeight: 'bold', marginBottom: 0 }}>M.I. ORGANIZAÇÃO DE DOCUMENTOS LTDA.</p>
                    <p style={{ color: 'white', fontWeight: 'bold', marginBottom: 0 }}>CNPJ 05.155.459/0001-88</p>
                </div>
                <Row style={{ flexDirection: 'column', marginTop: 30, justifyContent: 'flex-end' }}>
                    <p style={{ color: 'white', fontSize: 16, marginBottom: 0 }} >Despachante Isabel</p>
                    <p style={{ color: 'white', fontSize: 16, marginBottom: 0 }} >Rua Ephigenio Pereira da Cruz, 1337</p>
                    <p style={{ color: 'white', fontSize: 16, marginBottom: 0 }} >Pioneiros – Fazenda Rio Grande – PR</p>
                    <a href="https://digitran.app" target="_blank" style={{ color: 'white', marginTop: 20, fontSize: 16, marginBottom: 0 }} >www.digitran.app</a>
                </Row>
            </div>
        </Modal>
    )
}