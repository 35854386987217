import { IfElse } from "./If"

export const ForEach = ({ items, render, wrapper = null, empty = null }) => {
    
    const fn = wrapper || (() => null);

    return (
        <IfElse cond={(items || []).length == 0}>
            <>{empty}</>
            <IfElse cond={wrapper}>
                <>{fn((items?.map((item, index) => render && render({ item, index }))))}</>
                <>{items?.map((item, index) => render && render({ item, index }))}</>
            </IfElse>
        </IfElse>
    )
}