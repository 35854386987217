import React, { useState } from 'react'
import { Row, Col,  Modal, PageHeader } from 'antd';
import { FAQBlock } from '~/containers/FAQBlock';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import '~/styles/transparentModal.css'

const helpContent = [{
    title: "O que é o Digitran?",
    content: ["O Digitran é um app para você parcelar multas, IPVA, licenciamento e DPVAT do Detran/PR, de forma rápida e simples!"]
}, {
    title: "É seguro?",
    content: [
        "O Digitran foi desenvolvido a oferecer agilidade e facilidade com toda a segurança. O app esta de acordo com as novas diretrizes da LGPD (Lei Geral de Proteção de Dados). Tudo para garantir ainda mais segurança aos usuários.",
        "Além de toda a segurança, disponibilizamos um canal de contato para suporte caso você tenha alguma dúvida."
    ]
}, {
    title: "Após efetuar o pagamento, o que ocorre?",
    content: [
        "Quando o pagamento ocorre, ele é computador pelo sistema do Digitran e uma vez aprovado pela operadora do cartão de crédito é realizado a quitação dos débitos junto ao DETRAN/PR.",
        "Ao efetuar o pagamento dentro do APP você terá acesso a aba Pedidos, onde será exibido o status de todas as tranções realizadas dentro do APP."
    ]
}, {
    title: "Em quanto tempo as multas são quitadas?",
    content: [
        "A aprovação do pagamento junto a operadora do cartão de crédito pode levar até 1 dia útil + 1 dia útil para quitação dos débitos junto ao DETRAN/PR.",
        "Os prazos informados são prazos máximos, podendo a validação do pagamento e quitação dos débitos serem realizados antes do período informado."
    ]
}, {
    title: "Posso pagar multas de outros veiculos que não estejam no meu nome?",
    content: [
        `Pode sim, basta ter o Renavan do veículo para efetuar a pesquisa, selecionar os débitos e efetuar o pagamento.`,
        `Neste caso, você poderá efetuar o cadastro para pagamento em seu nome ou no nome do titular do RENAVAM desde que tenha os dados dele. A Nota Fiscal do serviço do Digitran será emitida com os dados usados no cadastro.`
    ]
}, {
    title: "E se eu precisar de suporte?",
    content: ["Temos um Whatsapp para atendimento que estará a sua disposição de segunda a sexta das 08:30 as 17h."]
}];

export const HelpBlock = ({ title, content }) => {
    const [collapsed, setCollapsed] = useState(true);

    return (
        <div style={{ marginBottom: 10, width: `100%`, textAlign: 'left' }}>
            <a href={"#"} onClick={() => setCollapsed(!collapsed)} style={{ marginBottom: 10 }}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ flex: 1 }}>
                        <span style={{ fontWeight: "bold", color: 'white' }}>{title}</span>
                    </div>
                    <div style={{ width: 16, marginLeft: 15 }}>
                        { collapsed ? <CaretDownOutlined style={{ color: 'white' }}/> : <CaretUpOutlined style={{ color: 'white' }}/>}
                    </div>
                </div>
            </a>
            {!collapsed && (
                <div style={{ marginTop: 5 }}>
                    { content.map(line => <p style={{ color: 'white' }}>{line}</p>)}
                </div>
            )}
        </div>
    );
}


export class HelpModal extends React.Component {
    render() {
        const { visible, onClose } = this.props;
        return (
            <Modal visible={visible} onCancel={onClose} centered style={{ backgroundColor: 'transparent' }} header={null} footer={null} bodyStyle={{ display: 'flex', flexDirection: 'column', background: 'linear-gradient(135deg, rgba(0,199,73,1) 0%, rgba(0,243,63,1) 100%)', borderRadius: 15, minHeight: '90vh' }}>
                <Row>
                    <PageHeader title={<span style={{ color: "white" }}>Ajuda</span>} style={{ paddingLeft: 0 }}  />
                </Row>
                <Row gutter={0} align="stretch" style={{ marginTop: 50, flex: 1 }}>
                    <Col xs={24} align="middle">
                        {helpContent.map((q) => <HelpBlock title={q.title} content={q.content} />)}
                    </Col>
                </Row>
                <Row>
                    <FAQBlock />
                </Row>
            </Modal>
        )
    }
}