import React from 'react'
import { Form, Input } from 'antd';
import { User } from '~/core/models/User';

export class PasswordSection extends React.Component {
    formRef = null;

    state = {
        password: {}
    }
    
    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.state.password = this.getInitState();
    }

    getInitState() {
        const fields = ['password', 'confirmPassword'];
        return fields.reduce((o, field) => ({ ...o, [field]: { value: null, validateStatus: null, errorMsg: null } }), {});
    }

    validatePasswordSection(field, value) {
        const isValid = User.validate(field, value);

        switch (field) {
            case 'password':
                return {
                    value,
                    validateStatus: isValid ? null : "error",
                    errorMsg: isValid ? null : "Senha inválida"
                }
            case 'confirmPassword':
                const userTmp = new User();
                userTmp.password = this.state.password.password.value;
                const isConfirmValid = userTmp.validate('confirmPassword', value);
                return {
                    value,
                    validateStatus: isConfirmValid ? null : "error",
                    errorMsg: isConfirmValid ? null : "As senhas não conferem"
                }
        }
    }

    setFields(fields, callback) {
        const { password } = this.state;

        for (const [field, value] of Object.entries(fields)) {
            password[field] = this.validatePasswordSection(field, value);
        }

        this.setState({ password }, () => callback && callback(password));
    }

    renderErrorMessage(errorMsg) {
        return (
            errorMsg && <span style={{ color: 'white', fontSize: 10, paddingLeft: 5, float: 'left' }}>
                {errorMsg}
            </span>
        )
    }

    render() {

        const { password } = this.state;
        const { onValidateFields } = this.props;

        return (
            <Form ref={this.formRef} layout="vertical" onFieldsChange={(changedValues, allFields) => {
                const objectFields = Array.from(allFields).reduce((o, field) => ({ ...o, [field.name[0]]: field.value }), {});
                this.setFields(objectFields, (newState) => {
                    onValidateFields && onValidateFields(
                        Object.entries(newState).reduce((bool, field) => bool && field[1].validateStatus != 'error', true),
                        objectFields
                    );
                })
            }}>
                <Form.Item hasFeedback name="password" validateStatus={password.password.validateStatus} help={this.renderErrorMessage(password.password.errorMsg)}>
                    <Input.Password placeholder="Cadastre uma senha" className="input" style={{ borderRadius: 50 }} />
                </Form.Item>
                <Form.Item hasFeedback name="confirmPassword" validateStatus={password.confirmPassword.validateStatus} help={this.renderErrorMessage(password.confirmPassword.errorMsg)}>
                    <Input.Password placeholder="Confirme a senha" className="input" style={{ borderRadius: 50 }} />
                </Form.Item>
            </Form>
        )
    }
}