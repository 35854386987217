import React from 'react'
import { Row, Button, Result } from 'antd';
import { CheckCircleFilled, RightOutlined } from '@ant-design/icons';

import '~/styles/input.css'

export class SuccessContainer extends React.Component {

    render() {
        const { onNext, transaction } = this.props;

        return (
            <div
                style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    background: 'linear-gradient(135deg, rgba(0,199,73,1) 0%, rgba(0,243,63,1) 100%)',
                    borderTopLeftRadius: 25,
                    borderTopRightRadius: 25,
                    paddingLeft: 10,
                    paddingRight: 10,
                    paddingTop: 15,
                    boxShadow: '1px 1px 5px #ccc'
                }}
            >
                <Row style={{ flex: 1, paddingTop: 15, paddingLeft: 10, paddingRight: 10 }}>
                    <div style={{ color: 'white', width: '100%' }}>
                        <Result
                            className={"resultContentTransparent"}
                            icon={<CheckCircleFilled style={{ color: 'white' }} />}
                            title={<span style={{ color: 'white' }}>PAGAMENTO REALIZADO!</span>}
                            subTitle={<span style={{ color: 'white' }}>TRANSACÃO #{transaction.id}</span>}
                            extra={[
                                <Button className={'button-secondary'} style={{ flex: 1, borderWidth: 0, marginLeft: 10, borderRadius: 10, textAlign: 'center' }} onClick={onNext}>Ir para minha conta <RightOutlined /></Button>
                            ]}
                        >
                            <p style={{ border: 'none', marginBottom: '2em' }}>
                                * Duis autem vem iriure dolor in hendrerit in vulputate velit esse molestie consequa Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.
                            </p>
                            <p style={{ border: 'none', marginBottom: '2em' }}>
                                * Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat Saiba mais.
                            </p>
                        </Result>

                    </div>
                </Row>
                <Row style={{ padding: 10 }}>

                </Row>
            </div>
        )
    }
}