import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import { UserStore } from './store/UserStore';
import { CardStore } from './store/CardStore';

import { StartScreen } from './screens/StartScreen';
import { SearchScreen } from './screens/SearchScreen';
import { HomeScreen } from './screens/HomeScreen';

import "antd/dist/antd.css";
import "./styles/menu.css";
import './styles/App.css';

class App extends React.Component {

	async componentDidMount() {
		const isLogged = UserStore.isLogged();
		if(isLogged) {
			const user = await UserStore.account();
			await CardStore.initCardStore(`${user.id}`, `${user.token}`);
		}
	}

	render() {
		return (
			<Router>
				<Switch>
					<Route exact path="/" component={StartScreen} />
					<Route exact path="/search" component={SearchScreen} />
					<Route exact path="/home" component={HomeScreen} />
				</Switch>
			</Router>
		)
	}
}

export default App;
