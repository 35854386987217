import React from 'react';
import { Result, Row } from 'antd';
import { WarningFilled, ClockCircleOutlined, CheckOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { IfElse } from '~/components/If';
import { ForEach } from '~/components/ForEach';

import '~/styles/input.css'

const formatDate = (jsDate) => new dayjs(jsDate).format('DD/MM/YYYY | HH:mm');

export const PaymentsContainer = (transactions, onTransactionClick) => {

    return (
        <IfElse cond={(transactions || []).length == 0}>
            <ForEach
                items={transactions}
                empty={(
                    <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
                        <Result
                            icon={<WarningFilled style={{ color: "#00c749" }} />}
                            title={"Não há pagamentos para esse veículo"}
                        />
                    </div>
                )}
                wrapper={(children) => (
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                        <div style={{ flex: 1 }}>
                            {children}
                        </div>
                    </div>
                )}
                render={({ item: transaction }) => (
                    <div style={{ padding: 10, cursor: 'pointer' }} onClick={() => onTransactionClick && onTransactionClick(transaction)}>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <span style={{ fontWeight: 'bold', fontSize: 12 }}>TRANSAÇÃO #{transaction.id}</span>
                            <span style={{ fontSize: 12 }}>{formatDate(transaction.created_at)}</span>
                        </div>
                        <div>
                            <span style={{ fontSize: 10 }}>
                                <StatusBlock status={transaction.status} />
                            </span>
                        </div>
                    </div>
                )}
            />
        </IfElse>
    )
}

const StatusBlock = ({ status }) => {
    switch (status) {
        case 'SCHEDULED':
        case 'PROCESSING':
            return (
                <Row style={{ alignItems: 'center' }}>
                    <span>EM PROCESSAMENTO</span>
                    <span style={{ marginLeft: 5 }}>
                        <ClockCircleOutlined />
                    </span>
                </Row>
            )
        case 'PAYED':
            return (
                <Row style={{ alignItems: 'center' }}>
                    <span>FINALIZADA</span>
                    <span style={{ marginLeft: 5 }}>
                        <CheckOutlined />
                    </span>
                </Row>
            );
        default:
            return (
                <div>
                    {status}
                </div>
            );
    }
}