import React from 'react'
import { Col, Row, Input, Button, Skeleton } from 'antd';
import { SearchOutlined, LoadingOutlined, MenuOutlined } from '@ant-design/icons';
import { Renavam } from '~/core/tools/Renavam';

const headerStyle = {
    padding: 15,
    borderBottomLeftRadius: 25,
    borderBottomRightRadius: 25,
    background: 'linear-gradient(135deg, rgba(0,199,73,1) 0%, rgba(0,243,63,1) 100%)',
    boxShadow: '1px 1px 5px #ccc'
};

export class SearchHeader extends React.Component {

    render() {
        const { vehicle, renavam, loading, isLogged, onLoadVehicle, onChangeRenavam } = this.props;
        return (
            <div className="headerComponent" style={headerStyle}>
                <Row gutter={0} style={{ alignItems: 'center' }}>
                    <Input
                        className="textWhite placeholderWhite"
                        placeholder="RENAVAM"
                        allowClear
                        bordered={false}
                        value={renavam}
                        onChange={(e) => onChangeRenavam && onChangeRenavam(e.target.value)}
                        onPressEnter={() => onLoadVehicle && onLoadVehicle()}
                        onBlur={() => onLoadVehicle && onLoadVehicle()}
                        disabled={loading}
                        style={{ flex: 1, color: 'white', backgroundColor: '#00c749' }}
                        prefix={
                            loading ? <LoadingOutlined spin style={{ color: 'white' }} /> : <SearchOutlined style={{ color: 'white' }} />
                        }
                    />
                    {
                        isLogged && (
                            <Button style={{ backgroundColor: 'transparent', color: 'white', border: 'none', boxShadow: 'none' }} onClick={() => this.setState({ showDrawer: true })}>
                                <MenuOutlined style={{ fontSize: 25 }} />
                            </Button>
                        )
                    }
                </Row>
                {
                    loading ? (
                        <Row style={{ paddingTop: 20, alignItems: 'stretch' }}>
                            <Col xs={11} style={{ paddingRight: 25 }}>
                                <Skeleton paragraph={{ rows: 2 }} active size={"small"} round title={false} />
                            </Col>
                            <Col style={{ borderWidth: 1, borderColor: 'white', borderStyle: 'solid' }} />
                            <Col xs={11} style={{ paddingLeft: 25, paddingRight: 5 }}>
                                <Skeleton paragraph={{ rows: 2 }} active size={"small"} round title={false} />
                            </Col>
                        </Row>
                    ) : (
                        <Row style={{ paddingTop: 20, alignItems: 'stretch' }}>
                            <Col xs={11}>
                                <Row style={{ color: 'white' }}>{Renavam.format(`${vehicle.renavam}`)}</Row>
                                <Row style={{ fontWeight: 'bold', fontSize: 20, color: 'white' }}>{vehicle.placa}</Row>
                            </Col>
                            <Col style={{ borderWidth: 1, borderColor: 'white', borderStyle: 'solid' }} />
                            <Col xs={11} style={{ paddingLeft: 25 }}>
                                <Row style={{ fontWeight: 'bold', color: 'white' }}>{vehicle.modelo}</Row>
                                <Row style={{ color: 'white' }}>{vehicle.cor}</Row>
                            </Col>
                        </Row>
                    )
                }
            </div>
        );
    }
}