import React from 'react';

export const FAQBlock = () => {
    return (
        <div style={{ borderTop: '1px solid white', paddingTop: 10, textAlign: 'left', flex: 1 }}>
            <span style={{ fontWeight: 'bold', color: 'white' }}>Fale conosco:</span>
            <br />
            <a href="tel:5541991388583"><span style={{ color: 'white' }}>(41) 99138-8583</span></a>
            <br />
            <a href="mailto:contato@digitran.app">
                <span style={{ color: 'white' }}>contato@digitran.app</span>
            </a>
        </div>
    )
}
