import React from 'react'
import { Row, message, Result, Tabs, Button } from 'antd';
import { LeftOutlined, RightOutlined, UserOutlined } from '@ant-design/icons';
import { UserStore } from '~/store/UserStore';
import { DebitsContainer } from '~/containers/search/DebitsContainer';
import { ParcelsContainer } from '~/containers/search/ParcelsContainer';
import { LoginModal } from '~/containers/start/LoginModal';
import { ForgotPasswordModal } from '~/containers/start/ForgotPasswordModal';
import { SignupModal } from '~/containers/start/SignupModal';
import { ConfirmContainer } from '~/containers/search/ConfirmContainer';
import { MenuDrawer } from '~/containers/MenuDrawer';
import { SuccessContainer } from '~/containers/search/SuccessContainer';
import { withRouter } from 'react-router-dom';
import { CardStore } from '~/store/CardStore';
import { CartStore } from '~/store/CartStore';
import { PaymentModal } from '~/containers/menu/PaymentModal';
import { Card } from '~/core/models';
import { PageWraper } from '~/components/PageWrapper';
import { LoadingOrChildren } from '~/components/LoadingOrChildren';
import { SearchHeader } from '~/containers/search/SearchHeader';

import '~/styles/input.css'

const { TabPane } = Tabs;

class SearchScreenClass extends React.Component {
    state = {
        renavam: null,
        loading: false,
        vehicle: {},
        parcels: [],
        card: {},
        transaction: null,
        isLogged: false,
        selectedParcel: null,
        activeKey: "DEBITS",
        showLogin: false,
        showSignup: false,
        showDrawer: false,
        showForgotPassword: false,
        showPayment: false
    }

    async setAsyncState(state) {
        return new Promise(resolve => this.setState(state, resolve));
    }

    async componentDidMount() {
        const [vehicle, isLogged] = await Promise.all([
            UserStore.getSelectedVehicle(),
            UserStore.isLogged()
        ])

        await this.setAsyncState({ vehicle, isLogged, renavam: vehicle.renavam })
    }

    async loadVehicle() {
        try {
            const { renavam } = this.state;
            await this.setAsyncState({ loading: true });
            await UserStore.setSelectedRenavam(renavam);

            const vehicle = await UserStore.getSelectedVehicle();
            const state = {
                vehicle,
                loading: false,
                renavam: vehicle.renavam,
                activeKey: "DEBITS"
            }

            await this.setAsyncState(state)
        } catch (err) {
            await this.setAsyncState({ loading: false, }, () => {
                message.warning({ content: err.message, key: 'selectVehicleError', duration: 1 });
            });
        }
    }

    renderHeader() {
        const { vehicle, renavam, loading, isLogged } = this.state;
        return (
            <SearchHeader
                renavam={renavam}
                onChangeRenavam={(e) => this.setState({ renavam: e })}
                onLoadVehicle={() => this.loadVehicle()}
                loading={loading}
                vehicle={vehicle}
                isLogged={isLogged}
            />
        );
    }

    renderDrawer() {
        const { showDrawer } = this.state;
        return (
            <MenuDrawer
                visible={showDrawer}
                onSelectRenavam={(renavam) => this.setState({ showDrawer: false, renavam }, this.loadVehicle)}
                onClose={() => this.setState({ showDrawer: false })}
            />
        )
    }

    async toggleDebitSelection(debitIndex, selected) {
        const { vehicle: vehicleData } = this.state;
        const debit = vehicleData.debits[debitIndex];

        switch (debit.type) {
            case 'MULTA':
            case 'DPVAT_ANTERIOR':
                if (!selected) {
                    vehicleData.debits.forEach((d) => {
                        d.selected = ['LICENCIAMENTO', 'LICENCIAMENTO_ANTERIOR'].indexOf(d.type) > -1 ? false : d.selected;
                    });
                }
                break;
            case 'DPVAT':
                if (!selected) {
                    vehicleData.debits.forEach((d) => {
                        d.selected = ['LICENCIAMENTO'].indexOf(d.type) > -1 ? false : d.selected;
                    });
                }
                break;
            case 'LICENCIAMENTO':
                if (selected) {
                    vehicleData.debits.forEach((d) => {
                        d.selected = ['MULTA', 'DPVAT', 'DPVAT_ANTERIOR', 'LICENCIAMENTO_ANTERIOR'].indexOf(d.type) > -1 ? true : d.selected;
                    });
                }
                break;
            case 'LICENCIAMENTO_ANTERIOR':
                if (selected) {
                    vehicleData.debits.forEach((d) => {
                        if (d.type == 'MULTA' || d.type == 'DPVAT_ANTERIOR') {
                            d.selected = true;
                        }
                    });
                } else {
                    vehicleData.debits.forEach((d) => {
                        if (d.type == 'LICENCIAMENTO') {
                            d.selected = false;
                        }
                    });
                }
                break;
        }

        vehicleData.debits[debitIndex].selected = selected;
        await this.setAsyncState({ vehicle: vehicleData });
    }

    async calculateParcels() {
        const { vehicle: vehicleData } = this.state;
        const total = vehicleData.debits.reduce((t, d) => d.selected ? t + d.value : t, 0);
        const parcelsData = await UserStore.simulateParcels(total);
        return parcelsData ? parcelsData.parcelas : [];
    }

    async selectParcel(parcel, parcelIndex) {
        const { parcels } = this.state;
        parcels.forEach((p, pIndex) => p.selected = pIndex == parcelIndex);
        await this.setAsyncState({ selectedParcel: parcel, parcels });
    }

    async prevTab() {

        const { activeKey } = this.state;
        const { history } = this.props;

        switch (activeKey) {
            case 'DEBITS':
                if (history.length > 0) {
                    history.goBack();
                } else {
                    history.replace('/');
                }
                break;
            case 'PARCELS':
                this.setState({ activeKey: "DEBITS" });
                break;
            case 'PAYMENTS':
                this.setState({ activeKey: "PARCELS" });
                break;
        }
    }

    async nexTab() {
        const { activeKey, vehicle, selectedParcel, isLogged } = this.state;

        switch (activeKey) {
            case 'DEBITS':
                if (vehicle.debits.filter((d) => d.selected).length == 0) {
                    return message.warning({
                        content: "Por favor, selecione ao menos um débito!",
                        key: 'searchScreen',
                        duration: 1
                    });
                }

                const parcels = await this.calculateParcels();
                if (parcels && parcels.length > 0) {
                    parcels[0].selected = true;
                }

                this.setState({
                    parcels,
                    selectedParcel: parcels ? parcels[0] : null,
                    activeKey: "PARCELS"
                });
                break;
            case 'PARCELS':
                if (!selectedParcel) {
                    return message.warning({
                        content: "Por favor, selecione uma opção!",
                        key: 'searchScreen',
                        duration: 1
                    });
                }

                if (isLogged) {
                    const card = await CardStore.getActiveCard();
                    this.setState({ card, activeKey: "PAYMENTS" });
                } else {
                    this.setState({ activeKey: "PAYMENTS" });
                }

                break;
            case 'PAYMENTS':
                const transactionResponse = await this.checkout();
                if (transactionResponse) {
                    return this.setAsyncState({
                        activeKey: "SUCCESS",
                        transaction: transactionResponse
                    });
                }
                break;
        }
    }

    async checkout() {
        try {
            const { vehicle, selectedParcel } = this.state;
            const activeCard = await CardStore.getActiveCard();

            activeCard.flag = Card.getFlag(activeCard.number);

            const response = await CartStore.checkout({
                debits: (vehicle.debits || []).filter(d => d.selected),
                parcels: selectedParcel,
                renavam: vehicle.renavam,
                card: activeCard
            });

            if (!response.success) {
                message.warning(response.error, 1);
            }

            return response.data;
        } catch (err) {
            console.log(err);
            message.warning("Ocorreu um erro ao efetuar a transação", 1);
        }
    }

    renderLoginModal() {
        const { showLogin } = this.state;
        return (
            <LoginModal
                visible={showLogin}
                onClose={() => this.setState({ showLogin: false })}
                onForgotPasswordClick={() => this.setState({ showForgotPassword: true })}
                onLogged={async () => {
                    const card = await CardStore.getActiveCard();
                    this.setState({ showLogin: false, card, isLogged: UserStore.isLogged() })
                }}
            />
        )
    }

    renderForgotPasswordModal() {
        const { showForgotPassword } = this.state;
        return (
            <ForgotPasswordModal visible={showForgotPassword} onClose={() => this.setState({ showForgotPassword: false })} />
        )
    }

    renderSignupModal() {
        const { showSignup } = this.state;
        return (
            <SignupModal
                visible={showSignup}
                onClose={() => this.setState({ showSignup: false })}
                onSignuped={async () => {
                    const card = await CardStore.getActiveCard();
                    this.setState({
                        showSignup: false,
                        card,
                        isLogged: UserStore.isLogged()
                    })
                }}
            />
        )
    }

    renderPaymentModal() {
        const { showPayment } = this.state;
        return (
            <PaymentModal
                visible={showPayment}
                onClose={() => this.setState({ showPayment: false })}
                onSave={async () => {
                    const card = await CardStore.getActiveCard();
                    this.setState({
                        showPayment: false,
                        card
                    })
                }}
            />
        )
    }

    renderPaymentTab() {
        const { vehicle, selectedParcel, isLogged, card } = this.state;
        const buttonStyle = { marginLeft: 10, marginRight: 10, borderRadius: 15 };

        if (!isLogged) {
            return (
                <Result
                    status="warning"
                    style={{ alignSelf: 'center' }}
                    icon={<UserOutlined style={{ color: "#00c749" }} />}
                    subTitle="Para continuar você precisar estar logado"
                    extra={
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <Button className="button-primary" style={buttonStyle} onClick={() => this.setState({ showLogin: true })}> Entrar </Button>
                            <Button className="button-primary" style={buttonStyle} onClick={() => this.setState({ showSignup: true })}> Cadastrar </Button>
                        </div>
                    }
                />
            )
        }

        if (!card) {
            return (
                <Result
                    status="warning"
                    style={{ alignSelf: 'center' }}
                    icon={<UserOutlined style={{ color: "#00c749" }} />}
                    subTitle="Para continuar você precisar cadastrar um cartão"
                    extra={
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <Button className="button-primary" style={{ marginLeft: 10, marginRight: 10, borderRadius: 15 }} onClick={() => this.setState({ showPayment: true })}> CADASTRAR CARTÃO </Button>
                        </div>
                    }
                />
            )
        }

        const order = { debits: (vehicle.debits || []).filter((debit) => debit.selected), parcels: selectedParcel, card }
        return (<ConfirmContainer order={order} onNext={() => this.nexTab()} onBack={() => this.prevTab()} />)
    }

    renderFooterButtons() {
        const { activeKey, vehicle } = this.state;
        if (["PAYMENTS", "SUCCESS"].indexOf(activeKey) > -1) {
            return null;
        }

        const { debits } = vehicle;
        const nextButtonDisabled = !(debits && debits.length > 0);
        const nextButtonStyle = {
            flex: 1,
            borderWidth: 0,
            marginLeft: 10,
            borderRadius: 10,
            textAlign: 'right',
            opacity: nextButtonDisabled ? 0.5 : 1
        }

        return (
            <Row style={{ justifyContent: 'space-between', padding: 10 }}>
                <Button className={'button-secondary'} style={{ flex: 1, borderWidth: 0, marginRight: 10, borderRadius: 10, textAlign: 'left' }} onClick={() => this.prevTab()}>
                    <LeftOutlined />Voltar
                </Button>
                <Button className={'button-primary'} style={nextButtonStyle} onClick={() => this.nexTab()} disabled={nextButtonDisabled}>
                    Próximo <RightOutlined />
                </Button>
            </Row>
        )
    }

    render() {
        const { history } = this.props;
        const { loading, vehicle, parcels, selectedParcel, activeKey, transaction } = this.state;
        return (
            <div style={{ backgroundColor: '#2F3A4F' }}>
                <PageWraper>
                    {this.renderHeader()}
                    <LoadingOrChildren loading={loading}>
                        {
                            activeKey != "SUCCESS" ? (
                                <Tabs className="searchTabBar" activeKey={activeKey} style={{ flex: 1, marginTop: 20, marginLeft: 10, marginRight: 10 }}>
                                    <TabPane tab="DÉBITOS" key="DEBITS" disabled={activeKey != "DEBITS"} style={activeKey == "DEBITS" ? { display: 'flex' } : {}} >
                                        <DebitsContainer
                                            debits={vehicle.debits || []}
                                            onCheckedDebit={this.toggleDebitSelection.bind(this)}
                                        />
                                    </TabPane>
                                    <TabPane tab="PARCELAS" key="PARCELS" disabled={activeKey != "PARCELS"} style={activeKey == "PARCELS" ? { display: 'flex' } : {}}>
                                        <ParcelsContainer parcels={parcels} selectedParcel={selectedParcel} onSelectedParcels={this.selectParcel.bind(this)} />
                                    </TabPane>
                                    <TabPane tab="PAGAMENTO" key="PAYMENTS" disabled={activeKey != "PAYMENTS"} style={activeKey == "PAYMENTS" ? { display: 'flex', justifyContent: 'center' } : {}}>
                                        {this.renderPaymentTab()}
                                    </TabPane>
                                </Tabs>
                            ) : (
                                <div style={{ display: "flex", flex: 1, marginTop: 20, marginLeft: 10, marginRight: 10 }}>
                                    {!!transaction && <SuccessContainer transaction={transaction} onNext={() => history.replace("/home")} />}
                                </div>
                            )
                        }
                    </LoadingOrChildren>
                    {this.renderFooterButtons()}
                </PageWraper>
                {this.renderLoginModal()}
                {this.renderForgotPasswordModal()}
                {this.renderSignupModal()}
                {this.renderPaymentModal()}
                {this.renderDrawer()}
            </div>
        )
    }
}

export const SearchScreen = withRouter(SearchScreenClass);