import React from 'react';
import { Empty, Radio } from 'antd';
import { Currency } from '~/core/tools/Currency';

const formatMoney = (price) => Currency.toPtBr(price)

const ParcelItem = ({ parcel, parcelIndex, onSelectedParcels }) => {
    return (
        <div style={{ padding: 10, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Radio key={`parcel-${parcelIndex}`} style={{ marginRight: 10 }} checked={parcel.selected} onChange={(e) => onSelectedParcels && onSelectedParcels(parcel, parcelIndex)} />
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flex: 1 }}>
                <span style={{ fontWeight: 'bold', fontSize: 12 }}>{parcel.numeroParcelas}x</span>
                <span style={{ fontWeight: 'bold', fontSize: 12 }}>{formatMoney(+parcel.valorParcela)}</span>
            </div>
        </div>
    )
}

export class ParcelsContainer extends React.Component {
    render() {
        const { parcels, selectedParcel, onSelectedParcels } = this.props;

        if ((parcels || []).length == 0) {
            return (
                <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description={
                            <span>
                                Não há parcelas para esse valor
                            </span>
                        }
                    />
                </div>
            )
        }

        return (
            <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                <div style={{ flex: 1 }}>
                    {parcels.map((parcel, parcelIndex) => <ParcelItem parcel={parcel} parcelIndex={parcelIndex} onSelectedParcels={onSelectedParcels} />)}
                </div>
                <div style={{ border: 0, borderTopWidth: 1, borderStyle: 'solid', paddingTop: 10, paddingBottom: 10 }}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <span style={{ fontWeight: 'bold' }}>Valor parcelado</span>
                        <span>{formatMoney(+selectedParcel.valorParcelado)}</span>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <span style={{ fontWeight: 'bold' }}>Taxa de serviço</span>
                        <span>{formatMoney(+selectedParcel.taxas.interna)}</span>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <span style={{ fontWeight: 'bold' }}>Juros do cartão</span>
                        <span>{formatMoney(+selectedParcel.taxas.externa)}</span>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <span style={{ fontWeight: 'bold' }}>Valor total</span>
                        <span>{formatMoney(+selectedParcel.total)}</span>
                    </div>
                </div>
            </div>
        )
    }
}