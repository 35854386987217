import React from 'react'
import { Form, Input } from 'antd';
import { Address } from '~/core/models/Address';
import { MaskedInput } from 'antd-mask-input';

export class AddressSection extends React.Component {
    formRef = null;

    state = {
        address: {}
    }

    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.state.address = this.getInitState();
    }

    getInitState() {
        const fields = ['cep', 'address', 'number', 'complement', 'neighborhood', 'city', 'state'];
        return fields.reduce((o, field) => ({ ...o, [field]: { value: null, validateStatus: null, errorMsg: null } }), {});
    }

    validateAddressSection(field, value) {
        const isValid = Address.validate(field, value);

        switch (field) {
            case 'cep':
                return {
                    value,
                    validateStatus: isValid ? null : "error",
                    errorMsg: isValid ? null : "CEP inválido"
                }
            case 'address':
                return {
                    value,
                    validateStatus: isValid ? null : "error",
                    errorMsg: isValid ? null : "Endereço inválido"
                }
            case 'number':
                return {
                    value,
                    validateStatus: isValid ? null : "error",
                    errorMsg: isValid ? null : "Número inválido"
                }
            case 'neighborhood':
                return {
                    value,
                    validateStatus: isValid ? null : "error",
                    errorMsg: isValid ? null : "Bairro inválido"
                }
            case 'city':
                return {
                    value,
                    validateStatus: isValid ? null : "error",
                    errorMsg: isValid ? null : "Cidade inválida"
                }
            case 'state':
                return {
                    value,
                    validateStatus: isValid ? null : "error",
                    errorMsg: isValid ? null : "Estado inválido"
                }
            case 'complement':
                return {
                    value,
                    validateStatus: null,
                    errorMsg: null
                }
        }
    }

    async searchCep(cep) {
        try {
            const { address } = this.state;
            if (cep) {
                const findedAddress = await Address.searchAddressByCEP(cep);
                if (findedAddress) {
                    const newAddress = {
                        ...address
                    }

                    Object.entries({ ...findedAddress, complement: null, number: null }).forEach((entry) => {
                        newAddress[entry[0]] = this.validateAddressSection(entry[0], entry[1]);
                    })

                    this.setState({ address: newAddress }, () => {
                        this.formRef.current.setFieldsValue({
                            cep: cep,
                            address: newAddress.address.value,
                            number: null,
                            complement: null,
                            neighborhood: newAddress.neighborhood.value,
                            city: newAddress.city.value,
                            state: newAddress.state.value
                        })
                    });
                } else {
                    this.setState({
                        address: {
                            cep: this.validateAddressSection('cep', cep),
                            address: this.validateAddressSection('address', null),
                            number: this.validateAddressSection('number', null),
                            complement: this.validateAddressSection('complement', null),
                            neighborhood: this.validateAddressSection('neighborhood', null),
                            city: this.validateAddressSection('city', null),
                            state: this.validateAddressSection('state', null)
                        }
                    }, () => {
                        this.formRef.current.setFieldsValue({
                            cep: cep,
                            address: null,
                            number: null,
                            complement: null,
                            neighborhood: null,
                            city: null,
                            state: null
                        })
                    });
                }
            }
        } catch (err) {
            console.log(err);
        }
    }

    setFields(fields, callback) {
        const { address } = this.state;

        for (const [field, value] of Object.entries(fields)) {
            address[field] = this.validateAddressSection(field, value);
        }

        this.setState({ address }, () => callback && callback(address));
    }

    renderErrorMessage(errorMsg) {
        return (
            errorMsg && <span style={{ color: 'white', fontSize: 10, paddingLeft: 5, float: 'left' }}>
                {errorMsg}
            </span>
        )
    }

    render() {

        const { address } = this.state;
        const { onValidateFields } = this.props

        return (
            <Form ref={this.formRef} layout="vertical" onValuesChange={(changedValues, allFields) => {
                const objectFields = { ...allFields };
                objectFields.cep = objectFields.cep ? objectFields.cep.replace(/[^0-9]/g, "") : null

                this.setFields(objectFields, async (newState) => {
                    console.log(changedValues);
                    if (changedValues.cep && objectFields.cep.length == 8) {
                        await this.searchCep(objectFields.cep);
                    }

                    onValidateFields && onValidateFields(
                        Object.entries(newState).reduce((bool, field) => bool && field[1].validateStatus != 'error', true),
                        objectFields
                    );
                })
            }}>
                <Form.Item hasFeedback name="cep" validateStatus={address.cep.validateStatus} help={this.renderErrorMessage(address.cep.errorMsg)}>
                    <MaskedInput mask="00.000-000" placeholder="CEP" className="input" style={{ borderRadius: 50 }} />
                </Form.Item>
                <Form.Item hasFeedback name="address" validateStatus={address.address.validateStatus} help={this.renderErrorMessage(address.address.errorMsg)}>
                    <Input placeholder="Endereço" className="input" style={{ borderRadius: 50 }} readOnly />
                </Form.Item>
                <Form.Item hasFeedback name="number" validateStatus={address.number.validateStatus} help={this.renderErrorMessage(address.number.errorMsg)}>
                    <Input placeholder="Número" className="input" style={{ borderRadius: 50 }} />
                </Form.Item>
                <Form.Item hasFeedback name="complement" validateStatus={address.complement.validateStatus} help={this.renderErrorMessage(address.complement.errorMsg)}>
                    <Input placeholder="Complemento" className="input" style={{ borderRadius: 50 }} />
                </Form.Item>
                <Form.Item hasFeedback name="neighborhood" validateStatus={address.neighborhood.validateStatus} help={this.renderErrorMessage(address.neighborhood.errorMsg)}>
                    <Input placeholder="Bairro" className="input" style={{ borderRadius: 50 }} readOnly />
                </Form.Item>
                <Form.Item hasFeedback name="city" validateStatus={address.city.validateStatus} help={this.renderErrorMessage(address.city.errorMsg)}>
                    <Input placeholder="Cidade" className="input" style={{ borderRadius: 50 }} readOnly />
                </Form.Item>
                <Form.Item hasFeedback name="state" validateStatus={address.cep.validateStatus} help={this.renderErrorMessage(address.state.errorMsg)}>
                    <Input placeholder="Estado" className="input" style={{ borderRadius: 50 }} readOnly />
                </Form.Item>
            </Form>
        )
    }
}