import React from 'react'
import { Button, Modal, PageHeader, Row } from 'antd';
import { ClockCircleOutlined, CheckOutlined, FileOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import '~/styles/transparentModal.css'
import { Currency } from '~/core/tools/Currency';
import { ForEach } from '~/components/ForEach';
import { If } from '~/components/If';

const DateFormat = ({ date, format = 'DD/MM/YYYY HH:mm' }) => {
    const dayjs_date = new dayjs(date);
    return dayjs_date.format(format)
}

export const PaymentDetailsModal = ({ visible, onClose, transaction }) => {

    const modal_props = {
        visible: visible,
        onCancel: onClose,
        centered: true,
        style: { backgroundColor: 'transparent' },
        header: null,
        footer: null,
        bodyStyle: {
            display: 'flex',
            flexDirection: 'column',
            background: 'linear-gradient(135deg, rgba(0,199,73,1) 0%, rgba(0,243,63,1) 100%)',
            borderRadius: 15,
            minHeight: '90vh'
        }
    }

    const modal_title = (
        <span style={{ color: "white" }}>
            TRANSAÇÃO #{transaction.id}
        </span>
    )

    return (
        <Modal {...modal_props}>
            <PageHeader
                style={{ paddingLeft: 0, paddingBottom: 0 }}
                title={modal_title}
            />
            <Row>
                <span style={{ color: "white", fontSize: 10 }}>
                    <StatusBlock status={transaction.status} />
                </span>
            </Row>
            <Row style={{ marginBottom: '2em' }}>
                <span style={{ fontSize: 12, color: 'white' }}>
                    <DateFormat
                        date={transaction.created_at}
                        format='DD/MM/YYYY | HH:mm'
                    />
                </span>
            </Row>
            <div style={{ display: 'flex', flexDirection: 'column', color: 'white', width: '100%', flex: 1 }}>
                <>
                    <p>DÉBITOS</p>
                    <Debits debits={transaction?.debits} />
                </>
                <>
                    <p>PAGAMENTO</p>
                    <Parcels parcels={transaction?.parcels} />
                </>
            </div>
            <Row style={{ justifyContent: 'space-between', padding: 10 }}>
                <Button className={'button-secondary'} style={{ flex: 1, borderWidth: 0, marginLeft: 10, borderRadius: 10, textAlign: 'center' }} onClick={() => { }}>
                    VISUALIZAR NF E COMPROVANTES <FileOutlined />
                </Button>
            </Row>
        </Modal>
    )
}

const Debits = ({ debits }) => {
    return (
        <ForEach
            items={debits}
            empty={null}
            wrapper={(children) => (
                <div style={{ marginBottom: '2em' }}>
                    {children}
                </div>
            )}
            render={({ items: debit }) => {
                return (
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flex: 1 }}>
                            <span style={{ fontWeight: 'bold', fontSize: 12 }}>{debit.description}</span>
                            <span style={{ fontSize: 12 }}>{Currency.toPtBr(+debit.value)}</span>
                        </div>
                    </div>
                )
            }}
        />
    )
}

const Parcels = ({ parcels }) => {
    return (
        <If cond={!!parcels && parcels.numeroParcelas}>
            <div style={{ marginBottom: '2em' }}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <span style={{ fontWeight: 'bold', fontSize: 12 }}>{parcels.numeroParcelas}x </span>
                    <span style={{ fontSize: 12 }}>{Currency.toPtBr(+parcels.valorParcela)}</span>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <span style={{ fontWeight: 'bold', fontSize: 12 }}>Valor parcelado</span>
                    <span style={{ fontSize: 12 }}>{Currency.toPtBr(+parcels.valorParcelado)}</span>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <span style={{ fontWeight: 'bold', fontSize: 12 }}>Encargos e Taxa de serviço</span>
                    <span style={{ fontSize: 12 }}>{Currency.toPtBr(+parcels.taxas.interna)}</span>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <span style={{ fontWeight: 'bold', fontSize: 12 }}>Juros do cartão</span>
                    <span style={{ fontSize: 12 }}>{Currency.toPtBr(+parcels.taxas.externa)}</span>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <span style={{ fontWeight: 'bold' }}>Valor total</span>
                    <span >{Currency.toPtBr(+parcels.total)}</span>
                </div>
            </div>
        </If>
    )
}

const StatusBlock = (status) => {
    switch (status) {
        case 'SCHEDULED':
        case 'PROCESSING':
            return (
                <Row style={{ alignItems: 'center' }}>
                    <span>EM PROCESSAMENTO</span>
                    <span style={{ marginLeft: 5 }}>
                        <ClockCircleOutlined />
                    </span>
                </Row>
            )
        case 'PAYED':
            return (
                <Row style={{ alignItems: 'center' }}>
                    <span>FINALIZADA</span>
                    <span style={{ marginLeft: 5 }}>
                        <CheckOutlined />
                    </span>
                </Row>
            );
        default:
            return (
                <div>
                    {status}
                </div>
            );
    }
}