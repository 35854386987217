import { Store } from 'secure-webstore';

export class CardStore {
    store = null;
    status = null;

    static async initCardStore(storeName, passphrase) {
        if (this.status == 'INITIALIZED') {
            return this.store;
        }

        try {
            this.store = new Store(storeName, passphrase);
            this.status = 'INITIALIZED';
            return await this.store.init();
        } catch(err) {
            this.store.clear();
            this.status = null;
            return await CardStore.initCardStore(storeName, passphrase);
        }        
    }

    static async loadCardStore(encryptedCardStore) {
        return this.store.import(encryptedCardStore);
    }

    static async exportCardStore() {
        return this.store.export();
    }

    static async listCards() {
        try {
            const cards = await this.store.get('userCards');
            return cards;
        } catch (err) {
            return [];
        }
    }

    static async addCard(cardData) {
        try {
            const cards = [cardData];
            await this.store.set('userCards', cards);
            return cards[cards.length - 1];
        } catch (err) {
            return null;
        }
    }

    static async removeCard(hashCard) {
        const cards = await CardStore.listCards();
        await this.store.set('userCards', cards.filter(c => c.hash != hashCard));
    }

    static async getActiveCard() {
        const cards = await CardStore.listCards();
        return cards && cards.length > 0 ? cards[0] : null;
    }

    static async getBandeiraId() {

    }
}