import React from 'react'
import { Modal, PageHeader } from 'antd'
import '../../styles/transparentModal.css'

export const PrivacyPolicyModal = ({ visible, onClose }) => {
    return (
        <Modal visible={visible} onCancel={onClose} centered style={{ backgroundColor: 'transparent' }} header={null} footer={null} bodyStyle={{ display: 'flex', flexDirection: 'column', background: 'linear-gradient(135deg, rgba(0,199,73,1) 0%, rgba(0,243,63,1) 100%)', borderRadius: 15, minHeight: '90vh' }}>
            <PageHeader title={<span style={{ color: "white" }}>Política de privacidade</span>} style={{ paddingLeft: 0 }} />
            <div style={{ display: 'flex', flexDirection: 'column', marginTop: 30, flex: 1 }}>
                <p style={{ color: 'white', fontSize: 12, marginBottom: 10 }}>
                    Esta página informa sobre nossas políticas relativas à coleta, uso e divulgação de dados pessoais quando você usa o aplicativo "Digitran.APP"  desenvolvido pela empresa M.I. Organização de Documentos Ltda, CNPJ 05.155.459/0001-88, Rua Ephigenio Pereira da Cruz, 1337 - Fazenda Rio Grande - PR, www.digitran.app, www.despachanteisabel.com.br, contato@digitran.app, servicos@despachanteisabel.com.br)
                </p>
                <p style={{ color: 'white', marginBottom: 10, fontSize: 12 }}>
                    Ao usar o aplicativo, você concorda com os seguintes termos:
                    <br />
                    <br />
                    <ol>
                        <li>
                            <p>
                                O que o app faz com suas informações pessoais:
                                <br />
                                <br />
                                As informações pessoais inseridas no app, como CPF, CNPJ, RG e Renavam ou placa do veículo, servem apenas para realização da consulta de situação em nosso servidor e não ficam armazenadas remotamente. Nem os dados inseridos nem o resultado das consultas são armazenados, portanto apenas o próprio usuário do aplicativo tem acesso à elas.
                            </p>
                        </li>
                        <li>
                            <p>
                                Provedores de serviço:
                                <br />
                                <br />
                                Além dos serviços abaixo, utilizamos também o Google Play Services em nosso aplicativo. Para ler sua política de privacidade acesse o link https://www.google.com/policies/privacy/
                            </p>
                        </li>
                        <li>
                            <p>
                                Google Analytics:
                                <br />
                                <br />
                                O Google Analytics é um serviço de análise da web oferecido pelo Google que rastreia e relata o tráfego do website. O Google usa os dados coletados para rastrear e monitorar o uso do nosso serviço. Esses dados são compartilhados com outros serviços do Google. O Google pode usar os dados coletados para contextualizar e personalizar os anúncios de sua própria rede de publicidade.
                                <br />
                                <br />
                                Para mais informações sobre as práticas de privacidade do Google, visite a página do Google Privacy & Terms: http://www.google.com/intl/pt/policies/privacy/
                            </p>
                        </li>
                        <li>
                            <p>
                                Validade:
                                <br />
                                <br />
                                Ressaltamos que nossos aplicativos são disponibilizados para download exclusivamente através da Play Store (loja oficial de aplicativos do Google) portanto os termos e informações aqui descritos são válidos somente para os nossos aplicativos baixados e instalados através das lojas citadas acima. Não podemos garantir a procedência de qualquer aplicativo que porventura venha a ser disponibilizado e/ou instalado de outra maneira.
                            </p>
                        </li>
                        <li>
                            <p>
                                Dados de cartão de crédito:
                                <br />
                                <br />
                                Os dados do cartão de crédito são armazenados de forma criptografada no seu celular, só sendo utilizados em caso de sua aprovação para algum pagamento;
                            </p>
                        </li>
                        <li>
                            <p>
                                Cancelamento da conta:
                                <br />
                                <br />
                                Você poderá apagar sua conta dentro do APP acessando a sessão “Ajustes” > Usuário > Encerrar conta;
                            </p>
                        </li>
                        <li>
                            <p>
                                Dúvidas sobre Privacidade:
                                <br />
                                <br />
                                Se você tiver algum questionamento ou dúvida com relação à Política de Privacidade dos nossos aplicativos ou qualquer prática aqui descrita, poderá entrar em contato conosco através do email contato@digitran.app onde teremos grande prazer em lhe atender. 
                                <br />
                                <br />
                                Nos reservamos ao direito de atualizar nossa política de privacidade sempre que julgarmos necessário. Caso façamos alterações nesta política de privacidade, iremos notificá-lo sobre a atualização através da página oficial do app no Google Play, pelo app ou mesmo por outros tipos de avisos que acharmos convenientes. Assim, você sempre ficará atualizado. 
                            </p>
                        </li>
                        <li>
                            <p>
                                Concordância:
                                <br />
                                <br />
                                Continuando a utilizar os nossos aplicativos você indicará que concorda com os termos declarados e em ficar vinculado por qualquer uma das alterações feitas nos termos desta Política de Privacidade. Se você não concordar com algum dos termos, você deve evitar qualquer uso dos nossos aplicativos.
                            </p>
                        </li>
                    </ol>
                </p>
            </div>
        </Modal>
    )
}