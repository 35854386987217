import React from 'react'
import { Row, Col, Input, Form, Button, message, Modal, PageHeader } from 'antd';
import digitranLogo from '~/assets/logo.svg'
import { FAQBlock } from '~/containers/FAQBlock';
import { UserStore } from '~/store/UserStore';

import '~/styles/transparentModal.css'

export class ForgotPasswordModal extends React.Component {
    state = {
        loading: false,
        helpMessage: null,
        validateStatus: null,
    }

    formRef = null;
    constructor(props) {
        super(props);
        this.formRef = React.createRef();
    }

    async onRecoverPassword() {
        try {
            this.setState({ loading: true });

            const form = this.formRef.current;

            const fields = await form.validateFields(['email']);
            const success = await UserStore.recoverPassword(fields.email);

            this.setState({ loading: false });

            if (success) {
                message.success({
                    content: 'E-mail enviado!',
                    key: 'recoverPassword',
                    duration: 1
                });
            } else {
                message.warning({
                    content: 'Ocorreu um erro ao recuperar a sua senha!',
                    key: 'recoverPassword',
                    duration: 1
                });
            }
        } catch (err) {
            message.warning({
                content: 'Ocorreu um erro ao recuperar a sua senha!',
                key: 'recoverPassword',
                duration: 1
            });
        }
    }

    render() {
        const { visible, onClose } = this.props;
        const { loading } = this.state;
        return (
            <Modal visible={visible} onCancel={onClose} centered style={{ backgroundColor: 'transparent' }} header={null} footer={null} bodyStyle={{ display: 'flex', flexDirection: 'column', background: 'linear-gradient(135deg, rgba(0,199,73,1) 0%, rgba(0,243,63,1) 100%)', borderRadius: 15, height: '90vh' }}>
                <Row>
                    <PageHeader title={<span style={{ color: "white" }}>Esqueci minha senha</span>} style={{ paddingLeft: 0 }} />
                </Row>
                <Row gutter={0} align="stretch" style={{ alignItems: 'center', flex: 1 }}>
                    <Col xs={24} align="middle">
                        <Row align="middle" className="pd" style={{ flex: 1 }}>
                            <Col xs={2} />
                            <Col xs={20} style={{ justifyContent: 'center' }}>
                                <img src={digitranLogo} width={"80%"} style={{ marginBottom: 40 }} />
                            </Col>
                        </Row>
                        <Row align="middle" style={{ flex: 1 }}>
                            <Col xs={2} />
                            <Col xs={20} >
                                <Form ref={this.formRef} layout="vertical">
                                    <Form.Item name="email" rules={[{ required: true, message: 'E-mail obrigatório' }]}>
                                        <Input placeholder="E-mail cadastrado" className="input" style={{ borderRadius: 50 }} />
                                    </Form.Item>
                                    <Form.Item>
                                        <Button className="button-secondary" shape="round" style={{ border: 'none' }} loading={loading} onClick={() => this.onRecoverPassword()}> RECUPERAR SENHA </Button>
                                    </Form.Item>
                                </Form>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <FAQBlock />
                </Row>
            </Modal>
        )
    }
}