import React from 'react'
import { Modal, PageHeader, Row } from 'antd'
import '../../styles/transparentModal.css'

export const TermsModal = ({ visible, onClose }) => {
    return (
        <Modal visible={visible} onCancel={onClose} centered style={{ backgroundColor: 'transparent' }} header={null} footer={null} bodyStyle={{ display: 'flex', flexDirection: 'column', background: 'linear-gradient(135deg, rgba(0,199,73,1) 0%, rgba(0,243,63,1) 100%)', borderRadius: 15, minHeight: '90vh' }}>
            <PageHeader title={<span style={{ color: "white" }}>Termos de uso</span>} style={{ paddingLeft: 0 }} />
            <div style={{ display: 'flex', flexDirection: 'column', marginTop: 30, flex: 1 }}>
                <div style={{ flex: 1 }}>
                    <p style={{ color: 'white', marginBottom: 10, fontSize: 12, fontWeight: 'bold'  }}>CONTRATO DE CONDIÇÕES GERAIS DE PRESTAÇÃO DE SERVIÇOS</p>
                    <p style={{ color: 'white', marginBottom: 10, fontSize: 12 }}>
                        Este contrato é celebrado entre nós, a M.I. Organização de Documentos Ltda, inscrita no CNPJ/ME sob o nº 05.155.459/0001-88, sediada na Rua Ephigenio Pereira da Cruz, 1337 - Fazenda Rio Grande - PR, e você, um usuário pessoa física (“Você”) que contrata os serviços que ofertamos através do aplicativo Digitran (“Aplicativo”).
                        <ol>
                            <li>Aceitação. Consideraremos que este contrato, suas regras e condições foram aceitos por Você quando Você criar sua conta no Aplicativo. Também consideraremos que Você reafirma sua aceitação a cada vez que fizer uma transação, ainda que ocorram alterações conforme a cláusula abaixo. Então, caso Você não concorde com esse contrato, não crie sua Conta.</li>
                            <li>Alterações. Poderemos alterar esse contrato, nossas regras e condições, o conteúdo ou o serviço do Aplicativo (inclusive para novas versões) a qualquer momento, sem aviso prévio. As atualizações estarão disponíveis no nosso site e Aplicativo e é de sua inteira responsabilidade verificá-las. Se tiver alguma dúvida, procure nossos canais de atendimento. Caso você não aceite as alterações deste Contrato, lembre-se de cancelar sua Conta.</li>
                            <li>Bloqueios e encerramento. Poderemos suspender, bloquear, interromper, total ou parcialmente, em caso de condutas com indícios de fraudes ou violação deste contrato, ou até mesmo encerrar definitivamente o fornecimento de serviços para Você a qualquer momento e sem que seja necessário apresentar nossos motivos, deixando de conceder ou negando acesso ao Aplicativo. Você também poderá encerrar seu relacionamento conosco acessando o menu “Ajustes” do Aplicativo.</li>
                            <li>Conta. Para utilização dos serviços de pagamento deste Contrato, Você deverá se cadastrar e criar uma conta no Aplicativo (“Conta”), informando seus dados cadastrais, os quais estarão sujeitos à nossa análise e aprovação. Se o seu cadastro não for aprovado por nós, Você não poderá criar sua Conta ou ela será encerrada a qualquer momento.</li>
                            <li>Cartões. Você poderá cadastrar cartões de crédito, emitidos de forma física (plástico) das bandeiras Visa e MasterCard na sua Conta para realização de pagamentos.</li>
                            <li>
                                Uso. Para utilizar o Aplicativo, Você deve:
                                <ul>
                                    <li>não compartilhar sua senha e não deixar ninguém acessar o Aplicativo com seu nome e com seus dados. Você será responsabilizado por qualquer ato a partir do uso indevido de seus dados, especialmente quando compartilhar o Aplicativo, senha ou seu celular com alguém, ou quando tenha perdido o dispositivo onde está instalado o Aplicativo sem nos comunicar imediatamente. Não nos responsabilizamos por qualquer dano emergente, moral ou material decorrente do uso indevido do Aplicativo ou dos seus dados de acesso (e-mail, senha etc.);</li>
                                </ul>
                            </li>
                            <li>
                                Funcionamento. Como funciona o Aplicativo:
                                <ul>
                                    <li>depois que Você baixa e instala o Aplicativo, precisamos te conhecer, e Você deve preencher o cadastro, que estará sujeito à nossa análise e aprovação, para começar a realizar suas transações dentro do Aplicativo;</li>
                                    <li>todas as transações possíveis através do Aplicativo dependem de acesso à internet, que Você deve providenciar e arcar com eventuais custos;</li>
                                    <li>os pagamentos através do Aplicativo somente serão efetuados por meio de cobrança no seu cartão de crédito cadastrado. Você sempre confirmará os pagamentos antes de efetuá-los;</li>
                                    <li>nós analisaremos os pagamentos efetuados, para te proteger contra possíveis fraudes, com nossas próprias ferramentas para análises de risco. Também poderemos cancelar a transação, se entendermos necessário;</li>
                                    <li>quando a transação for concretizada, você será notificado dentro do Aplicativo na seção “Transações”;</li>
                                    <li>fique atento ao realizar suas transações. Não nos responsabilizamos por erros, independente do motivo;</li>
                                    <li>Você deve reportar imediatamente qualquer erro ou má utilização do Aplicativo através da nossa Central de Ajuda. Essas informações são essenciais para mantermos o Aplicativo seguro.</li>
                                </ul>
                            </li>
                            <li>
                                Condutas proibidas. Há ações que Você está proibido de fazer, porque violam nosso propósito e filosofia e podem levar ao cancelamento da sua conta, dentre elas:
                                <ul>
                                    <li>desrespeitar as leis, especialmente as de direito autoral ou propriedade industrial; </li>
                                    <li>tentar obter acesso ou obter acesso a qualquer informação privilegiada sem autorização;</li>
                                    <li>interromper ou prejudicar o serviço do Aplicativo, os servidores ou redes a ele conectados;</li>
                                    <li>interferir na utilização do Aplicativo por outro usuário;</li>
                                    <li>praticar qualquer ato que sobrecarregue indevidamente o Aplicativo. Queremos criar uma rede de interação entre nossos usuários. Não prejudique ninguém e nem se prejudique;</li>
                                    <li>vincular, sem autorização, a imagem e/ou marca da Digitran a movimentos políticos ou partidários, sindicais, religiosos ou que tenham relação com uma das atividades ou condutas descritas neste item 8; e</li>
                                    <li>copiar, reproduzir, modificar, criar derivações, distribuir ou divulgar ao público qualquer conteúdo do site, ou Aplicativo, a não ser que tenha nossa expressa autorização.</li>
                                </ul>
                            </li>
                            <li>
                                Tarifas. Queremos revolucionar a forma de efetuar e receber pagamentos no dia-a-dia. Para concretizar esse objetivo, precisamos dos recursos das tarifas que cobramos de nossos usuários. Os valores não são fixos.
                                <ul>
                                    <li>Para informações sobre as tarifas, taxas e encargos, consulte o tarifário no nosso site e nos “Ajustes” do próprio Aplicativo.</li>
                                </ul>
                            </li>
                            <li>
                                Dados. Você é responsável pela veracidade dos seus dados pessoais, dos dados do Cartão que cadastrar no Aplicativo. Se pedirmos, Você deverá comprovar que é o dono do Cartão.
                                <ul>
                                    <li>Uso dos dados. Cumprimos obrigações regulatórias para Você usar nosso Aplicativo, como: validações cadastrais, prevenção à fraude e para cumprirmos estas e outras tarefas ao mesmo tempo em que trabalhamos a melhoria contínua da sua experiência, precisamos coletar e utilizar os seus dados, como nome, CPF e endereço;</li>
                                    <li>
                                        Finalidade. O uso dos seus dados poderá atender a diversas finalidades relacionadas ao desempenho de nossas atividades e, como citado anteriormente, para a melhoria da sua experiência, tais como:
                                        <ol>
                                            <li>divulgação, oferta e prestação de serviços;</li>
                                            <li>avaliação dos serviço;</li>
                                            <li>cumprimento de obrigações legais, regulatórias e requisições de autoridades administrativas e judiciais;</li>
                                            <li>para exercício regular de direitos em processos administrativos, judiciais e arbitrais;</li>
                                            <li>análise, gerenciamento e tratamento de potenciais riscos, incluindo os de crédito, fraude e segurança;</li>
                                            <li>verificação da sua identidade e dados pessoais, para fins de autenticação, segurança e/ou prevenção à fraude, realizada em sistemas eletrônicos próprios ou de terceiros;</li>
                                            <li>avaliação, manutenção e aprimoramento dos nossos produtos e serviços;</li>
                                            <li>envio de comunicação para Você;</li>
                                            <li>hipóteses de legítimo interesse, como desenvolvimento e ofertas de produtos e serviços.</li>
                                        </ol>
                                    </li>
                                    <li>
                                        Compartilhamento. Para atender às finalidades previstas nesse Contrato e na nossa Política de Privacidade, poderemos compartilhar seus dados pessoais, adotando todas as medidas técnicas e organizacionais necessárias, conforme a legislação aplicável, com:
                                        <ol>
                                            <li>parceiras prestadoras de serviços, localizadas no Brasil ou no exterior;</li>
                                            <li>órgãos reguladores e entidades públicas, inclusive administrativas e judiciais; e</li>
                                            <li>parceiros estratégicos para possibilitar a oferta de produtos e serviços.</li>
                                        </ol>
                                    </li>
                                </ul>
                            </li>
                            <li>Promoções. Poderemos realizar ações promocionais para nossos usuários. Para participar das promoções, é necessário que a sua Conta esteja válida, sem restrições e em conformidade com todas as regras descritas neste Contrato.</li>
                            <li>
                                Responsabilidades. Sua relação conosco precisa ser de confiança. Então, nós temos as seguintes atribuições e responsabilidades:
                                <ul>
                                    <li>Prestamos o serviço com o máximo de diligência e técnica, segundo parâmetros adotados por serviços similares, mas não nos responsabilizamos por qualquer dano eventualmente causado por falha ou interrupção de serviços.</li>
                                    <li>Arquivaremos os seus dados em ambiente virtual com certificado de segurança e criptografado com uso de chaves públicas. Gravaremos e arquivaremos todas as suas operações e transações conforme legislação aplicável, e poderemos utilizá-las como prova perante às autoridades administrativas, judiciais e/ou arbitrais, caso necessário.</li>
                                    <li>Não temos como prestar garantia de que o serviço do Aplicativo não apresente falhas, seja ininterruptamente utilizável e imune à corrupção, ataques, vírus, interferências, pirataria informática (hacking) ou quaisquer ataques de segurança, que serão considerados eventos de força maior, para todos os efeitos.</li>
                                    <li>Poderemos suspender ou encerrar quaisquer de nossos produtos e serviços prestados através do Aplicativo, temporária ou definitivamente, parcial ou totalmente, por razões técnicas ou qualquer outro motivo, sem informação prévia.</li>
                                </ul>
                            </li>
                            <li>
                                Cessão. Poderemos ceder os direitos e obrigações decorrentes deste Contrato para terceiros, sejam do mesmo grupo econômico ou não.
                                <ul>
                                    <li>Em razão da sua Conta ser pessoal e intransferível, seus direitos no âmbito deste contrato não poderão ser cedidos ou transferidos, no todo ou em parte, diretamente ou indiretamente, sem nosso prévio consentimento por escrito, sob pena de rescisão imediata deste Contrato e da impossibilidade de uso do Aplicativo.</li>
                                </ul>
                            </li>
                            <li>Propriedade intelectual. O Aplicativo, suas partes, sistemas e acessórios, pertencem a nós. Todos os direitos de propriedade intelectual relacionados ao Aplicativo são de propriedade ou licenciados por nós e estão protegidos pelo direito brasileiro. Nossos usuários, assim como Você, concordam que não estão autorizados a copiar, modificar, transferir ou explorar o Aplicativo, para tentar copiar ou igualar.</li>
                            <li>Vigência. Queremos que a nossa relação seja continuada, então esse Contrato tem prazo indeterminado. Mas poderemos rescindi-lo sem aviso prévio ou indenização.</li>
                            <li>Comunicação. Você pode falar conosco através do endereço de e-mail, pelo Fale Conosco ou número de telefone que fica dentro do nosso Aplicativo. Fique à vontade, sempre.</li>
                            <li>Confirmação. Já que leu até aqui, Você confirma e declara, para todos os fins de direito, que tomou conhecimento de todo o conteúdo deste Contrato, entendeu perfeitamente suas cláusulas e condições, e concorda com tudo através da criação e acesso à sua Conta no Aplicativo.</li>
                            <li>Conteúdo de terceiros. O Aplicativo pode ter links para sites e aplicativos de terceiros. Ao acessar esses links, você estará em um ambiente que possui outros contratos, termos de uso e políticas de privacidade aplicáveis, que não são de nossa responsabilidade. A existência desses links não significa nenhuma relação de endosso, patrocínio ou responsabilidade entre nós e esses terceiros.</li>
                        </ol>
                    </p>
                </div>
            </div>
        </Modal>
    )
}