export class Response {
    
    constructor(data, error) {
        this.data = data;
        this.error = error;    
        this.success = !!this.data && !this.error;
    }

    toJSON() {
        return {
            success: !!this.data && !this.error,
            data: this.data,
            error: this.error
        }
    }
}