import React from 'react';
import { Empty } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { Currency } from '~/core/tools/Currency';

const formatMoney = (price) => Currency.toPtBr(price);

const DebitItem = ({ debit, debitIndex, onCheckedDebit }) => {
    return (
        <div style={{ padding: 10, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Checkbox key={`debit-${debitIndex}`} style={{ marginRight: 10 }} checked={debit.selected} onChange={(e) => onCheckedDebit && onCheckedDebit(debitIndex, e.target.checked)} />
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flex: 1 }}>
                <span style={{ fontWeight: 'bold', fontSize: 12 }}>{debit.description}</span>
                <span style={{ fontWeight: 'bold', fontSize: 12 }}>{formatMoney(+debit.value)}</span>
            </div>
        </div>
    )
}

export class DebitsContainer extends React.Component {
    render() {
        const { debits, onCheckedDebit } = this.props;

        if ((debits || []).length == 0) {
            return (
                <div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description={
                            <span>
                                Não há débitos para esse veículos
                            </span>
                        }
                    />
                </div>
            )
        }

        return (
            <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                <div style={{ flex: 1 }}>
                    {debits.map((debit, debitIndex) => <DebitItem debit={debit} debitIndex={debitIndex} onCheckedDebit={onCheckedDebit} />)}
                </div>
                <div style={{ border: 0, borderTopWidth: 1, borderStyle: 'solid', paddingTop: 10, paddingBottom: 10 }}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <span style={{ fontWeight: 'bold' }}>Valor total dos débitos selecionados</span>
                        <span>{formatMoney(debits.reduce((t, d) => d.selected ? t + d.value : t, 0))}</span>
                    </div>
                </div>
            </div>
        )
    }
}