export const If = ({ cond, children, ...props }) => {
    if (cond) {
        return children;
    } else {
        return null;
    }
}

export const IfNot = ({ cond, children }) => {
    if (!cond) {
        return children;
    } else {
        return null;
    }
}

export const IfElse = ({ cond, children }) => {

    const cond_true = Array.isArray(children) ? children[0] : children;
    const cond_false = Array.isArray(children) && children.length > 0 ? children[1] : cond_true;
    if (cond) {
        return cond_true;
    } else {
        return cond_false;
    }
}

export const Switch = ({ value, children }) => {
    const childs = Array.isArray(children) ? children : [children];
    const cases = childs.filter(c => c);

    // const cond_true = Array.isArray(children) ? children[0] : children;
    // const cond_false = Array.isArray(children) && children.length > 0 ? children[1] : cond_true;

    return null;
}