import React from 'react'
import { Col, Row, Input, message, Result, Tabs, Button, Skeleton } from 'antd';
import { SearchOutlined, LoadingOutlined, MenuOutlined, WarningFilled } from '@ant-design/icons';
import { UserStore } from '~/store/UserStore';
import { withRouter } from 'react-router-dom';
import { MenuDrawer } from '~/containers/MenuDrawer';
import { DebitsContainer } from '~/containers/home/DebitsContainer';
import { PaymentsContainer } from '~/containers/home/PaymentsContainer';
import { PaymentDetailsModal } from '~/containers/home/payment/PaymentDetailsModal';
import { Renavam } from '~/core/tools/Renavam';
import { If, IfElse } from '~/components/If';
import '~/styles/input.css';

const { TabPane } = Tabs;


class HomeScreenClass extends React.Component {
    state = {
        renavam: null,
        loading: false,
        activeKey: 'DEBITS',
        hasMoreVehicles: false,
        vehicle: {},
        transactions: [],
        isLogged: false,
        showDrawer: false,
        selectedTransaction: null
    }

    async setAsyncState(state) {
        return new Promise(resolve => this.setState(state, resolve));
    }

    async componentDidMount() {
        let vehicle = await UserStore.getSelectedVehicle();
        const vehicles = await UserStore.listVehicles();

        const isLogged = UserStore.isLogged();
        const transactions = [];

        await this.setAsyncState({ loading: true });

        if (!vehicle) {
            if (vehicles.length > 0) {
                await UserStore.setSelectedRenavam(vehicles[0].renavam);
                vehicle = await UserStore.getSelectedVehicle();
            }
        }

        if (vehicle) {
            const paymentsResponse = await UserStore.listPayments(vehicle.renavam);
            transactions.push(...(paymentsResponse.data || []));
        }

        this.setState({
            loading: false,
            vehicle,
            hasMoreVehicles: vehicles.length > 1,
            isLogged,
            transactions,
            renavam: vehicle.renavam
        })
    }

    async loadVehicle() {
        try {
            const { renavam } = this.state;

            this.setState({ loading: true });

            await UserStore.setSelectedRenavam(renavam);
            const vehicle = await UserStore.getSelectedVehicle();

            const transactions = [];

            if (vehicle) {
                const paymentsResponse = await UserStore.listPayments(vehicle.renavam);
                transactions.push(...(paymentsResponse.data || []));
            }

            this.setState({
                loading: false,
                vehicle,
                transactions,
                renavam: vehicle.renavam
            })

        } catch (err) {
            this.setState({
                loading: false,
            }, () => {
                message.warning({
                    content: err.message,
                    key: 'selectVehicleError',
                    duration: 2
                });
            });
        }
    }

    async loadTransactionDetails(transaction) {
        const { id } = transaction;

        const messageClose = message.loading({
            content: 'Buscando detalhes da transação...',
            key: 'transactionKey'
        });

        const transactionResponse = await UserStore.transactionDetails(id);
        if (transactionResponse.success) {
            this.setState({
                selectedTransaction: transactionResponse.data
            }, () => {
                messageClose();
            });
        } else {
            message.warning({
                content: transactionResponse.error || "Ocorre um erro ao buscar a transação",
                duration: 1,
                key: "transactionKey"
            });
        }
    }

    renderHeader() {
        const { vehicle, renavam, loading } = this.state;
        return (
            <HeaderApp
                vehicle={vehicle}
                renavam={renavam}
                loading={loading}
                onLoadVehicle={() => this.loadVehicle()}
                onShowDrawer={() => this.setState({ showDrawer: true })}
                onRenavamChanged={(renavam) => this.setState({ renavam })}
            />
        )
    }

    renderDrawer() {
        const { showDrawer } = this.state;
        return (
            <MenuDrawer
                visible={showDrawer}
                onSelectRenavam={(renavam) => this.setState({ showDrawer: false, renavam }, this.loadVehicle)}
                onClose={() => this.setState({ showDrawer: false })}
            />
        )
    }

    renderNoVehicle() {
        return (
            <Result
                style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
                icon={<WarningFilled style={{ color: "#00c749" }} />}
                subTitle="Por favor, pesquise um veículo"
            />
        )
    }

    renderFullLoading() {
        return (
            <Result
                style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
                icon={<LoadingOutlined spin style={{ color: "#00c749" }} />}
                subTitle="Buscando informações do veículo"
            />
        )
    }

    renderPaymentDetailsModal() {
        const { selectedTransaction } = this.state;

        return (
            <PaymentDetailsModal
                visible={!!selectedTransaction}
                transaction={selectedTransaction || {}}
                onClose={() => this.setState({ selectedTransaction: null })}
            />
        )
    }

    render() {
        const { loading, activeKey, vehicle, transactions } = this.state;

        return (
            <div style={{ backgroundColor: '#2F3A4F' }}>
                <Row style={{ alignItems: 'stretch', minHeight: '100vh' }}>
                    <Col xs={0} sm={1} md={2} lg={7} xl={8} xxl={9}
                        style={{ boxShadow: '1px 1px 20px inset' }}
                    />
                    <Col xs={24} sm={22} md={20} lg={10} xl={8} xxl={6}
                        style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'rgb(242,242,242)', paddingLeft: 15, paddingRight: 15, minHeight: '100%', flex: 1 }}
                    >
                        {this.renderHeader()}
                        {!loading && !vehicle.renavam && this.renderNoVehicle()}
                        {loading && this.renderFullLoading()}
                        {
                            !!vehicle.renavam && !loading && (
                                <Tabs className="searchTabBar" activeKey={activeKey} style={{ flex: 1, marginTop: 20, marginLeft: 10, marginRight: 10 }} onChange={(key) => { this.setState({ activeKey: key }) }}>
                                    <TabPane tab="DÉBITOS" key="DEBITS" style={activeKey == "DEBITS" ? { display: 'flex' } : {}} >
                                        <DebitsContainer debits={vehicle.debits} />
                                    </TabPane>
                                    <If cond={transactions.length > 0}>
                                        <TabPane tab="PAGAMENTOS" key="PAYMENTS" style={activeKey == "PAYMENTS" ? { display: 'flex' } : {}} >
                                            <PaymentsContainer
                                                transactions={transactions}
                                                onTransactionClick={(t) => {
                                                    this.loadTransactionDetails(t)
                                                }}
                                            />
                                        </TabPane>
                                    </If>
                                </Tabs>
                            )
                        }
                    </Col>
                    <Col xs={0} sm={1} md={2} lg={7} xl={8} xxl={9} style={{ boxShadow: '1px 1px 20px inset' }} />
                </Row>
                {this.renderDrawer()}
                {this.renderPaymentDetailsModal()}
            </div>
        )
    }
}

const HeaderApp = ({ vehicle, renavam, loading, onRenavamChanged, onLoadVehicle, onShowDrawer }) => {

    const isLogged = UserStore.isLogged();

    const headerStyle = {
        padding: 15,
        borderBottomLeftRadius: 25,
        borderBottomRightRadius: 25,
        background: 'linear-gradient(135deg, rgba(0,199,73,1) 0%, rgba(0,243,63,1) 100%)',
        boxShadow: '1px 1px 5px #ccc'
    };

    return (
        <div className={"headerComponent"} style={headerStyle}>
            <Row gutter={0} style={{ alignItems: 'center' }}>
                <Input
                    className="textWhite placeholderWhite"
                    placeholder="RENAVAM"
                    allowClear
                    bordered={false}
                    value={renavam}
                    onChange={(e) => onRenavamChanged(e.target.value)}
                    onPressEnter={() => onLoadVehicle()}
                    onBlur={() => onLoadVehicle()}
                    disabled={loading}
                    style={{ flex: 1, color: 'white', backgroundColor: '#00c749' }}
                    prefix={
                        <IfElse cond={loading}>
                            <LoadingOutlined spin style={{ color: 'white' }} />
                            <SearchOutlined style={{ color: 'white' }} />
                        </IfElse>
                    }
                />
                <If cond={isLogged}>
                    <Button
                        style={{ backgroundColor: 'transparent', color: 'white', border: 'none', boxShadow: 'none' }}
                        onClick={() => onShowDrawer()}
                    >
                        <MenuOutlined style={{ fontSize: 25 }} />
                    </Button>
                </If>
            </Row>
            <IfElse cond={loading}>
                <Row style={{ paddingTop: 20, alignItems: 'stretch' }}>
                    <Col xs={11} style={{ paddingRight: 25 }}>
                        <Skeleton paragraph={{ rows: 2 }} active size={"small"} round title={false} />
                    </Col>
                    <Col style={{ borderWidth: 1, borderColor: 'white', borderStyle: 'solid' }} />
                    <Col xs={11} style={{ paddingLeft: 25, paddingRight: 5 }}>
                        <Skeleton paragraph={{ rows: 2 }} active size={"small"} round title={false} />
                    </Col>
                </Row>
                <If cond={!!vehicle}>
                    <Row style={{ paddingTop: 20, alignItems: 'stretch' }}>
                        <Col xs={11}>
                            <Row style={{ color: 'white' }}>{Renavam.format(`${vehicle.renavam}`)}</Row>
                            <Row style={{ fontWeight: 'bold', fontSize: 20, color: 'white' }}>{vehicle.placa}</Row>
                        </Col>
                        <Col style={{ borderWidth: 1, borderColor: 'white', borderStyle: 'solid' }} />
                        <Col xs={11} style={{ paddingLeft: 25 }}>
                            <Row style={{ fontWeight: 'bold', color: 'white' }}>{vehicle.modelo || "Modelo Desconhecido"}</Row>
                            <Row style={{ color: 'white' }}>{vehicle.cor || "Cor Desconhecida"}</Row>
                        </Col>
                    </Row>
                </If>
            </IfElse>
        </div>
    );
}

export const HomeScreen = withRouter(HomeScreenClass);