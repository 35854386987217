import Axios from 'axios'

export const UF = {
    "AC": "Acre",
    "AL": "Alagoas",
    "AP": "Amapá",
    "AM": "Amazonas",
    "BA": "Bahia",
    "CE": "Ceará",
    "DF": "Distrito Federal",
    "ES": "Espírito Santo",
    "GO": "Goiás",
    "MA": "Maranhão",
    "MT": "Mato Grosso",
    "MS": "Mato Grosso do Sul",
    "MG": "Minas Gerais",
    "PA": "Pará",
    "PB": "Paraíba",
    "PR": "Paraná",
    "PE": "Pernambuco",
    "PI": "Piauí",
    "RJ": "Rio de Janeiro",
    "RN": "Rio Grande do Norte",
    "RS": "Rio Grande do Sul",
    "RO": "Rondônia",
    "RR": "Roraima",
    "SC": "Santa Catarina",
    "SP": "São Paulo",
    "SE": "Sergipe",
    "TO": "Tocantins"
}

export class Address {
    cep = null;
    address = null;
    number = null;
    neighborhood = null;
    city = null;
    state = null;

    static async searchAddressByCEP(cep) {
        if (!Address.validate('cep', cep)) {
            return null;
        }
        const responseCep = await Axios.get(`https://viacep.com.br/ws/${cep}/json`);
        const findedAddress = responseCep.data;

        if (findedAddress.erro) {
            return null;
        }

        return Address.fromObject({
            cep: cep,
            address: findedAddress.logradouro,
            neighborhood: findedAddress.bairro,
            city: findedAddress.localidade,
            uf: findedAddress.uf
        });
    }

    static fromObject(obj) {
        const a = new Address;

        if (!obj)
            return a;

        a.neighborhood = obj.neighborhood;
        a.cep = obj.cep;
        a.city = obj.city;
        a.complement = obj.complement;
        a.address = obj.address;
        a.number = obj.number;
        a.state = obj.uf;
        return a;
    }

    static validate(field, value) {
        const a = new Address();
        return a.validate(field, value);
    }

    validate(field, value) {
        const valueField = value || this[field];
        switch (field) {
            case 'cep':
                return (/^[0-9]{8}$/).test(valueField);
            case 'address':
                return !!valueField;
            case 'number':
                return !!valueField;
            case 'neighborhood':
                return !!valueField;
            case 'city':
                return !!valueField;
            case 'state':
                return !!valueField;
        }
    }

}