import { Col, Row } from 'antd';

export const PageWraper = ({ children }) => {
    return (
        <Row style={{ alignItems: 'stretch', minHeight: '100vh' }}>
            <Col xs={0} sm={1} md={2} lg={7} xl={8} xxl={9} style={{ boxShadow: '1px 1px 20px inset' }} />
            <Col xs={24} sm={22} md={20} lg={10} xl={8} xxl={6} style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'rgb(242,242,242)', paddingLeft: 15, paddingRight: 15, minHeight: '100%', flex: 1 }}>
                {children}
            </Col>
            <Col xs={0} sm={1} md={2} lg={7} xl={8} xxl={9} style={{ boxShadow: '1px 1px 20px inset' }} />
        </Row>
    )
}
