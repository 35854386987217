import React from 'react'
import { Modal, PageHeader, Row, Col, Result } from 'antd'
import { UserStore } from '~/store/UserStore';
import { WarningFilled } from '@ant-design/icons';

import '../../styles/transparentModal.css'
import { Renavam } from '../../core/tools/Renavam';


export class VehicleModal extends React.Component {

    state = {
        vehicles: [],
        selectedRenavam: null
    }

    constructor(props) {
        super(props);
    }

    async componentDidMount() {
    }

    async componentDidUpdate(prevProps) {
        if(!prevProps.visible && this.props.visible) {
            const vehicles = await UserStore.listVehicles();
            const selectedRenavam = await UserStore.getSelectedRenavam();
            this.setState({ vehicles, selectedRenavam });
        }
    }

    
    render() {
        const { visible, onSelectRenavam, onClose } = this.props;
        const { vehicles, selectedRenavam } = this.state;

        return (
            <Modal visible={visible} onCancel={onClose} centered style={{ backgroundColor: 'transparent' }} header={null} footer={null} bodyStyle={{ display: 'flex', flexDirection: 'column', background: 'linear-gradient(135deg, rgba(0,199,73,1) 0%, rgba(0,243,63,1) 100%)', borderRadius: 15, minHeight: '90vh' }}>
                <PageHeader title={<span style={{ color: "white" }}>Meus veículos</span>} style={{ paddingLeft: 0 }} />
                <Row gutter={0} align="stretch" style={{ marginTop: 10, flex: 1 }}>
                    {
                        vehicles.length == 0 ? (
                            <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
                                <Result
                                    icon={<WarningFilled style={{ color: "white" }} />}
                                    title={<span style={{ color: 'white' }}>Não há veículos cadastrados</span>}
                                    subTitle={<span style={{ color: 'white' }}>Realize um pagamento para que seu veículo apareça aqui</span>}
                                />
                            </div>
                        ) : (
                            <Col xs={24}>
                                {
                                    vehicles.map((vehicle) => {
                                        const backgroundColor = vehicle.renavam == selectedRenavam ? 'white' : 'transparent';
                                        const color = vehicle.renavam == selectedRenavam ? '#00c749' : 'white';
                                        return (
                                            <div style={{ cursor: 'pointer', backgroundColor: backgroundColor, borderRadius: 10 }} onClick={() => onSelectRenavam && onSelectRenavam(vehicle.renavam)}>
                                                <Row style={{ paddingLeft: 20, paddingTop: 20, paddingBottom: 20, alignItems: 'stretch' }}>
                                                    <Col>
                                                        <Row style={{ fontWeight: 'bold', color: color, fontSize: 20 }}>{Renavam.format(`${vehicle.renavam}`)}</Row>
                                                        <Row style={{ color: color }}>{vehicle.placa} / {vehicle.modelo || "Modelo Desconhecido"} / {vehicle.cor || "Cor Desconhecida"} </Row>
                                                    </Col>
                                                </Row>
                                            </div>
                                        )
                                    })
                                }
                            </Col>
                        )
                    }
                </Row>
            </Modal>
        )
    }
}
