import React from 'react'
import { Form, Input } from 'antd';
import { User } from '~/core/models';
import { InputMask } from '~/components/InputMask';

export class UserDataSection extends React.Component {
    formRef = null;

    state = {
        user: {}
    }

    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.state.user = this.getInitState();
    }

    getInitState() {
        const fields = ['name', 'document', 'email', 'confirmEmail']
        return fields.reduce((o, field) => ({ ...o, [field]: { value: null, validateStatus: null, errorMsg: null } }), {});
    }

    validateUserSection(field, value) {
        const isValid = User.validate(field, value);

        switch (field) {
            case 'name':
                return {
                    value,
                    validateStatus: isValid ? null : "error",
                    errorMsg: isValid ? null : "Nome inválido"
                }
            case 'document':
                return {
                    value,
                    validateStatus: isValid ? null : "error",
                    errorMsg: isValid ? null : "Documento inválido"
                }
            case 'email':
                return {
                    value,
                    validateStatus: isValid ? null : "error",
                    errorMsg: isValid ? null : "E-mail inválido"
                }
            case 'confirmEmail':
                const userTmp = new User();
                userTmp.email = this.state.user.email.value;
                const isConfirmValid = userTmp.validate('confirmEmail', value);
                return {
                    value,
                    validateStatus: isConfirmValid ? null : "error",
                    errorMsg: isConfirmValid ? null : "Os e-mails não conferem"
                }
        }
    }

    setFields(fields, callback) {
        const { user } = this.state;

        for (const [field, value] of Object.entries(fields)) {
            user[field] = this.validateUserSection(field, value);
        }

        this.setState({ user }, () => callback && callback(user));
    }

    renderErrorMessage(errorMsg) {
        return (
            errorMsg && <span style={{ color: 'white', fontSize: 10, paddingLeft: 5, float: 'left' }}>
                {errorMsg}
            </span>
        )
    }

    render() {
        const { user } = this.state;
        const { onValidateFields } = this.props;

        return (
            <Form
                ref={this.formRef}
                layout="vertical"
                onFieldsChange={(changedValues, allFields) => {
                    const objectFields = Array.from(allFields).reduce((o, field) => ({ ...o, [field.name[0]]: field.value }), {});
                    objectFields.document = objectFields.document ? objectFields.document.replace(/[^0-9]/g, "") : null;
                    
                    this.setFields(objectFields, (newState) => {
                        onValidateFields && onValidateFields(
                            Object.entries(newState).reduce((bool, field) => bool && field[1].validateStatus != 'error', true),
                            objectFields
                        );
                    })
                }}
            >
                <Form.Item hasFeedback name="name" validateStatus={user.name.validateStatus} help={this.renderErrorMessage(user.name.errorMsg)}>
                    <Input placeholder="Nome completo/Razão Social" className="input" style={{ borderRadius: 50 }} />
                </Form.Item>
                <Form.Item hasFeedback name="document" validateStatus={user.document.validateStatus} help={this.renderErrorMessage(user.document.errorMsg)}>
                    <InputMask
                        alwaysShowMask={false}
                        mask={`${user.document.value || ''}`.replace(/[^0-9]/g, "").length <= 11 ? "999.999.999-999" : "99.999.999/9999-99"}
                        placeholder="CPF/CNPJ"
                        className="input"
                        maskChar={" "}
                        style={{ borderRadius: 50 }}
                    />
                </Form.Item>
                <Form.Item hasFeedback name="email" validateStatus={user.email.validateStatus} help={this.renderErrorMessage(user.email.errorMsg)}>
                    <Input placeholder="E-mail" className="input" style={{ borderRadius: 50 }} />
                </Form.Item>
                <Form.Item hasFeedback name="confirmEmail" validateStatus={user.confirmEmail.validateStatus} help={this.renderErrorMessage(user.confirmEmail.errorMsg)}>
                    <Input placeholder="Confirme seu e-mail" className="input" style={{ borderRadius: 50 }} />
                </Form.Item>
            </Form>
        )
    }
}