import React from 'react';
import { Button, Result, Row } from 'antd';
import { WarningFilled, RightOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { IfElse } from '~/components/If';
import { ForEach } from '~/components/ForEach';
import { Currency } from '~/core/tools/Currency';

import '~/styles/input.css'

export const DebitsContainer = ({ debits }) => {
    const history = useHistory();

    return (
        <IfElse cond={(debits || []).length == 0}>
            <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Result
                    icon={<WarningFilled style={{ color: "#00c749" }} />}
                    title={"Não há débitos para esse veículo"}
                />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                <div style={{ flex: 1 }}>
                    <ForEach
                        items={debits}
                        render={({ item: debit }) => (
                            <div style={{ padding: 10, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flex: 1 }}>
                                    <span style={{ fontWeight: 'bold', fontSize: 12 }}>{debit.description}</span>
                                    <span style={{ fontWeight: 'bold', fontSize: 12 }}>{Currency.toPtBr(+debit.value)}</span>
                                </div>
                            </div>
                        )}
                    />
                </div>
                <Row style={{ justifyContent: 'space-between', padding: 10 }}>
                    <Button
                        className={'button-primary'}
                        style={{ flex: 1, borderWidth: 0, marginLeft: 10, borderRadius: 10, textAlign: 'center' }}
                        onClick={() => history.push('/search')}
                    >
                        PAGAR DÉBITOS <RightOutlined />
                    </Button>
                </Row>
            </div>
        </IfElse>
    )
}