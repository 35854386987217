import React from 'react'
import { Row, Col, Input, Form, Button, message, Modal, PageHeader } from 'antd';
import digitranLogo from '~/assets/logo-colorida.svg'
import { Link, withRouter } from 'react-router-dom';
import { FAQBlock } from '~/containers/FAQBlock';
import { UserStore } from '~/store/UserStore';
import { CardStore } from '~/store/CardStore';
import '~/styles/transparentModal.css'

class LoginModalClass extends React.Component {
    formRef = null;

    constructor(props) {
        super(props);
        this.formRef = React.createRef();
    }

    componentDidMount() {

    }

    async makeLogin() {
        try {
            const { history, onLogged } = this.props;
            const form = this.formRef.current;

            const fields = await form.validateFields(['email', 'password']);

            message.loading({ content: 'Autenticando...', key: 'loginKey' });
            const response = await UserStore.login(fields.email, fields.password);
            if (response.success) {
                message.success({
                    content: 'Login realizado!',
                    key: 'loginKey',
                    duration: 1,
                    onClose: async () => {
                        const user = await UserStore.account();
                        await CardStore.initCardStore(`${user.id}`, `${user.token}`);
                        return onLogged ? onLogged() : history.push('/home');
                    }
                });
            } else {
                message.warning({
                    content: response.error,
                    key: 'loginKey',
                    duration: 1
                });
            }
        } catch (err) {

        }
    }

    render() {
        const { visible, onClose, onForgotPasswordClick } = this.props;
        return (
            <Modal visible={visible} onCancel={onClose} centered style={{ backgroundColor: 'transparent' }} header={null} footer={null} bodyStyle={{ display: 'flex', flexDirection: 'column', background: 'linear-gradient(135deg, rgba(0,199,73,1) 0%, rgba(0,243,63,1) 100%)', borderRadius: 15, height: '90vh' }}>
                <Row gutter={0} align="stretch" style={{ alignItems: 'center', flex: 1 }}>
                    <Col xs={24} align="middle">
                        <Row align="middle" className="pd" style={{ flex: 1 }}>
                            <Col xs={2} />
                            <Col xs={20} style={{ justifyContent: 'center' }}>
                                <img src={digitranLogo} width={"80%"} style={{ marginBottom: 40 }} />
                            </Col>
                        </Row>
                        <Row align="middle" style={{ flex: 1 }}>
                            <Col xs={2} />
                            <Col xs={20} >
                                <Form ref={this.formRef} layout="vertical">
                                    <Form.Item name="email" rules={[{ required: true, message: 'E-mail obrigatório' }]}>
                                        <Input placeholder="E-mail" className="input" style={{ borderRadius: 50 }} />
                                    </Form.Item>
                                    <Form.Item name="password" rules={[{ required: true, message: 'Senha obrigatória' }]}>
                                        <Input.Password placeholder="Senha" className="input" style={{ borderRadius: 50 }} />
                                    </Form.Item>
                                    <Form.Item>
                                        <Button className="button-secondary" shape="round" style={{ border: 'none' }} onClick={() => this.makeLogin()}> Entrar </Button>
                                    </Form.Item>
                                </Form>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <a onClick={onForgotPasswordClick} style={{ color: 'white' }}>Esqueci a minha senha</a>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <FAQBlock />
                </Row>
            </Modal>
        )
    }
}


export const LoginModal = withRouter(LoginModalClass)