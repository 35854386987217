import React, { useRef, useState } from 'react'
import { Row, Col, Input, Form, Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { withRouter, useHistory } from 'react-router-dom';

import { UserStore } from '~/store/UserStore';
import digitranLogo from '~/assets/logo-colorida.svg'
import { LoginModal } from '~/containers/start/LoginModal';
import { HelpModal } from '~/containers/start/HelpModal';
import { ForgotPasswordModal } from '~/containers/start/ForgotPasswordModal';
import { SignupModal } from '~/containers/start/SignupModal';
import { IfElse } from '~/components/If';

import '~/styles/login.css';
import '~/styles/input.css';

class StartScreenClass extends React.Component {
    state = {
        showLogin: false,
        showSignup: false,
        showHelp: false,
        showForgotPassword: false,
        loading: false,
        isLogged: false,
        renavamError: null
    }

    formRef = null;

    constructor(props) {
        super(props);
        this.formRef = React.createRef();
    }

    componentDidMount() {
        this.setState({
            isLogged: UserStore.isLogged()
        })
    }

    async searchRenavam() {
        try {
            this.setState({
                loading: true,
                renavamError: null
            });
            const { history } = this.props;

            const form = this.formRef.current;
            const fields = await form.validateFields(['renavam']);

            await UserStore.setSelectedRenavam(fields.renavam);
            history.push('/search');

        } catch (err) {
            this.setState({
                loading: false,
                renavamError: err.message
            });
        }
    }

    render() {
        const { loading, renavamError, isLogged } = this.state;

        return (
            <>
                <Row gutter={0} className="fullscreen" align="stretch">
                    <Col xs={24} sm={24} md={12} lg={10} xl={8} style={{ backgroundColor: '#2F3A4F' }}>
                        <Row align="middle" className="pd" style={{ height: '100vh' }}>
                            <Col flex="1">
                                <Row align="middle" className="pd" style={{ flex: 1 }}>
                                    <Col xs={2} />
                                    <Col xs={20} style={{ justifyContent: 'center', display: 'flex' }}>
                                        <img src={digitranLogo} width={"60%"} style={{ marginBottom: 40 }} />
                                    </Col>
                                </Row>

                                <Row style={{ flex: 1 }}>
                                    <Col xs={3} />
                                    <Col xs={18} >
                                        <RenavamSearch />
                                        <IfElse cond={isLogged}>
                                            <LoggedInButtons
                                                onLogout={() => {
                                                    UserStore.clearStore();
                                                    this.setState({ isLogged: false })
                                                }}
                                            />
                                            <LoggedOutButtons />
                                        </IfElse>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={0} sm={0} md={12} lg={14} xl={16} className={"login-background"}></Col>
                </Row>
            </>
        );
    }
}

const RenavamSearch = () => {
    const ref = useRef();
    const history = useHistory();

    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const searchRenavam = async () => {
        try {
            setLoading(true);
            setError(null);

            const form = ref.current;
            const fields = await form.validateFields(['renavam']);
            await UserStore.setSelectedRenavam(fields.renavam);

            history.push('/search');

        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    }

    return (
        <Form ref={ref} layout="vertical">
            <Form.Item name="renavam" rules={[{ required: true, message: 'Renavam obrigatório' }]} validateStatus={!!error ? 'error' : null} help={error}>
                <Input placeholder="Renavam" className="input" style={{ borderRadius: 50 }} />
            </Form.Item>
            <Form.Item>
                <Button
                    className="button-primary"
                    onClick={() => searchRenavam()}
                    shape="round"
                    style={{ border: 'none' }}
                    icon={<SearchOutlined />}
                    loading={loading}
                >
                    PESQUISAR
                </Button>
            </Form.Item>
        </Form>
    )
}

const LoggedInButtons = ({ onLogout }) => {
    const [showHelp, setShowHelp] = useState(false);
    const history = useHistory();
    const link_style = { color: 'white', fontWeight: 'lighter' };

    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <a onClick={() => history.push('/home')} style={link_style}>Ir para minha conta</a>
                <a onClick={() => setShowHelp(true)} style={link_style}>Ajuda</a>
                <a onClick={() => onLogout && onLogout()} style={link_style}>Sair</a>
            </div>
            <HelpModal
                visible={showHelp}
                onClose={() => setShowHelp(false)}
            />
        </>
    )
}

const LoggedOutButtons = ({ }) => {
    const [showLogin, setShowLogin] = useState(false);
    const [showSignup, setShowSignup] = useState(false);
    const [showHelp, setShowHelp] = useState(false);
    const [showForgotPassword, setShowForgotPassword] = useState(false);

    const link_style = { color: 'white', fontWeight: 'lighter' };

    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <a onClick={() => setShowLogin(true)} style={link_style}>Efetuar login</a>
                <a onClick={() => setShowSignup(true)} style={link_style}>Cadastrar</a>
                <a onClick={() => setShowHelp(true)} style={link_style}>Ajuda</a>
            </div>
            <LoginModal
                visible={showLogin}
                onClose={() => setShowLogin(false)}
                onForgotPasswordClick={() => setShowForgotPassword(true)}
            />
            <ForgotPasswordModal
                visible={showForgotPassword}
                onClose={() => setShowForgotPassword(false)}
            />
            <SignupModal
                visible={showSignup}
                onClose={() => setShowSignup(false)}
            />
            <HelpModal
                visible={showHelp}
                onClose={() => setShowHelp(false)}
            />
        </>
    )
}


export const StartScreen = withRouter(StartScreenClass);