import React from 'react'
import { Button, Form, Input, message, Modal, PageHeader } from 'antd'
import { CardStore } from '~/store/CardStore';
import { Card } from 'digitran-core/lib/Models/Card';
import { MaskedInput } from 'antd-mask-input';

import '../../styles/transparentModal.css'
import '../../styles/input.css'

export class PaymentModal extends React.Component {

    formRef = null;

    state = {
        infoChanged: false,
        initialValues: {},
        card: {}
    };

    constructor(props) {
        super(props);
        this.state.card = this.getInitState();
    }

    async componentDidMount() {
        const activeCard = await CardStore.getActiveCard();
        this.setState({
            initialValues: activeCard
        })
    }

    async componentDidUpdate(prevProps) {
        if (!prevProps.visible && this.props.visible) {
            const activeCard = await CardStore.getActiveCard();
            if (activeCard && activeCard.number) {
                this.setAllFields({ ...activeCard }, (card) => {
                    this.formRef && this.formRef.setFieldsValue(activeCard);
                });
            }
        }
    }

    getInitState() {
        const fields = ['number', 'name', 'expireDate', 'cvc'];
        return fields.reduce((o, field) => ({ ...o, [field]: { value: null, validateStatus: null, errorMsg: null } }), {});
    }

    async saveInfo() {
        try {
            const { onSave } = this.props;

            const { card: cardState } = this.state;
            const card = new Card();
            card.name = cardState.name.value;
            card.number = cardState.number.value;
            card.expireDate = cardState.expireDate.value;
            card.cvc = cardState.cvc.value;
            await CardStore.addCard(card);

            this.setState({ infoChanged: false });
            message.success("Informações do cartão cadastradas!", 1);
            onSave && onSave();
            
        } catch (err) {
            console.log(err);
        }
    }

    validateCardSection(field, value) {
        const isValid = Card.validate(field, value);

        switch (field) {
            case 'number':{
                const isValid = Card.validate(field, value.replace(/\s/g, ''));
                return {
                    value,
                    validateStatus: isValid ? null : "error",
                    errorMsg: isValid ? null : "Número do cartão inválido!"
                }
            }
            case 'name':
                return {
                    value,
                    validateStatus: isValid ? null : "error",
                    errorMsg: isValid ? null : "Nome impresso no cartão inválido!"
                }
            case 'expireDate':
                return {
                    value,
                    validateStatus: isValid ? null : "error",
                    errorMsg: isValid ? null : "Data de expiração inválida!"
                }
            case 'cvc':
                return {
                    value,
                    validateStatus: isValid ? null : "error",
                    errorMsg: isValid ? null : "CVC inválido!"
                }
        }
    }

    setAllFields(fields, callback) {
        const { card } = this.state;

        for (const [field, value] of Object.entries(fields)) {
            card[field] = this.validateCardSection(field, value);
        }

        this.setState({ infoChanged: true, card }, () => callback && callback(card));
    }

    renderErrorMessage(errorMsg) {
        return (
            errorMsg && <span style={{ color: 'white', fontSize: 10, paddingLeft: 5, float: 'left' }}>
                {errorMsg}
            </span>
        )
    }

    render() {
        const { visible, onClose } = this.props;
        const { infoChanged, card, initialValues } = this.state;

        return (
            <Modal visible={visible} onCancel={onClose} centered style={{ backgroundColor: 'transparent' }} header={null} footer={null} bodyStyle={{ display: 'flex', flexDirection: 'column', background: 'linear-gradient(135deg, rgba(0,199,73,1) 0%, rgba(0,243,63,1) 100%)', borderRadius: 15, minHeight: '90vh' }}>
                <PageHeader title={<span style={{ color: "white" }}>Pagamento</span>} style={{ paddingLeft: 0 }} />
                <div style={{ display: 'flex', flexDirection: 'column', marginTop: 30, flex: 1 }}>
                    <Form ref={(r) => this.formRef = r} layout="vertical" initialValues={initialValues} style={{ flex: 1, display: 'flex', flexDirection: 'column' }} onValuesChange={(changedValues, allFields) => {
                        const objectFields = allFields;
                        if(objectFields.number) {
                            objectFields.number = objectFields.number.replace(/[^0-9]/g, "")
                        }
                        
                        this.setAllFields(objectFields);
                    }}>
                        <Form.Item hasFeedback name="number" validateStatus={card.number.validateStatus} help={this.renderErrorMessage(card.number.errorMsg)}>
                            <MaskedInput mask="0000 0000 0000 0000" size="20" placeholder="Número do cartão" className="input" style={{ borderRadius: 50 }} />
                        </Form.Item>
                        <Form.Item hasFeedback name="name" validateStatus={card.name.validateStatus} help={this.renderErrorMessage(card.name.errorMsg)}>
                            <Input placeholder="Nome impresso no cartão" className="input" style={{ borderRadius: 50 }} />
                        </Form.Item>
                        <Form.Item hasFeedback name="expireDate" validateStatus={card.expireDate.validateStatus} help={this.renderErrorMessage(card.expireDate.errorMsg)}>
                            <MaskedInput mask="00/00" placeholder="MM/AA" className="input" style={{ borderRadius: 50 }} />
                        </Form.Item>
                        <Form.Item hasFeedback name="cvc" validateStatus={card.cvc.validateStatus} help={this.renderErrorMessage(card.cvc.errorMsg)}>
                            <MaskedInput mask="000" placeholder="CVC" className="input" style={{ borderRadius: 50 }} />
                        </Form.Item>
                        {
                            infoChanged && (
                                <Form.Item className={'flexEndInputControl'} style={{ flex: 1, justifyContent: 'flex-end' }}>
                                    <Button className="button-secondary" shape="round" style={{ border: 'none' }} onClick={() => this.saveInfo()}> SALVAR </Button>
                                </Form.Item>
                            )
                        }
                    </Form>
                </div>
            </Modal>
        )
    }
} 