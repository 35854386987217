import React from 'react'
import { Form, Input } from 'antd';
import { Card } from '~/core/models/Card';
import { MaskedInput } from 'antd-mask-input'

export class PaymentSection extends React.Component {
    formRef = null;

    state = {
        card: {}
    }

    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.state.card = this.getInitState();
    }

    getInitState() {
        const fields = ['number', 'name', 'expireDate', 'cvc'];
        return fields.reduce((o, field) => ({ ...o, [field]: { value: null, validateStatus: null, errorMsg: null } }), {});
    }

    validateCardSection(field, value) {
        const isValid = Card.validate(field, value);

        switch (field) {
            case 'number':
                return {
                    value,
                    validateStatus: isValid ? null : "error",
                    errorMsg: isValid ? null : "Número do cartão inválido!"
                }
            case 'name':
                return {
                    value,
                    validateStatus: isValid ? null : "error",
                    errorMsg: isValid ? null : "Nome impresso no cartão inválido!"
                }
            case 'expireDate':
                return {
                    value,
                    validateStatus: isValid ? null : "error",
                    errorMsg: isValid ? null : "Data de expiração inválida!"
                }
            case 'cvc':
                return {
                    value,
                    validateStatus: isValid ? null : "error",
                    errorMsg: isValid ? null : "CVC inválido!"
                }
        }
    }

    setFields(fields, callback) {
        const { card } = this.state;

        for (const [field, value] of Object.entries(fields)) {
            card[field] = this.validateCardSection(field, value);
        }

        this.setState({ card }, () => callback && callback(card));
    }

    renderErrorMessage(errorMsg) {
        return (
            errorMsg && <span style={{ color: 'white', fontSize: 10, paddingLeft: 5, float: 'left' }}>
                {errorMsg}
            </span>
        )
    }


    render() {

        const { onValidateFields } = this.props;
        const { card } = this.state;

        return (
            <Form ref={this.formRef} layout="vertical" onValuesChange={(changedValues, allFields) => {
                const objectFields = allFields;
                if(objectFields.number) {
                    objectFields.number = objectFields.number.replace(/[^0-9]/g, "")
                }
                
                this.setFields(objectFields, (newState) => {
                    onValidateFields && onValidateFields(
                        Object.entries(newState).reduce((bool, field) => bool && field[1].validateStatus != 'error', true),
                        objectFields
                    );
                })
            }}>
                <Form.Item hasFeedback name="number" validateStatus={card.number.validateStatus} help={this.renderErrorMessage(card.number.errorMsg)}>
                    <MaskedInput mask="0000 0000 0000 0000" size="20" placeholder="Número do cartão" className="input" style={{ borderRadius: 50 }} />
                </Form.Item>
                <Form.Item hasFeedback name="name" validateStatus={card.name.validateStatus} help={this.renderErrorMessage(card.name.errorMsg)}>
                    <Input placeholder="Nome impresso no cartão" className="input" style={{ borderRadius: 50 }} />
                </Form.Item>
                <Form.Item hasFeedback name="expireDate" validateStatus={card.expireDate.validateStatus} help={this.renderErrorMessage(card.expireDate.errorMsg)}>
                    <MaskedInput mask="00/00" placeholder="MM/AA" className="input" style={{ borderRadius: 50 }} />
                </Form.Item>
                <Form.Item hasFeedback name="cvc" validateStatus={card.cvc.validateStatus} help={this.renderErrorMessage(card.cvc.errorMsg)}>
                    <MaskedInput mask="000" placeholder="CVC" className="input" style={{ borderRadius: 50 }} />
                </Form.Item>
            </Form>
        )
    }
}