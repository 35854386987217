import React from 'react'
import { Row, Col, Button, message, Modal, Tabs, PageHeader } from 'antd';
import { FAQBlock } from '~/containers/FAQBlock';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { UserDataSection } from './signup/UserDataSection';
import { PasswordSection } from './signup/PasswordSection';
import { AddressSection } from './signup/AddressSection';
import { PaymentSection } from './signup/PaymentSection';

import '../../styles/input.css'
import '../../styles/transparentModal.css'
import '../../styles/customTabBar.css'
import { User } from 'digitran-core/lib/Models/User';
import { UserStore } from '~/store/UserStore';
import { CardStore } from '~/store/CardStore';
import { Address } from 'digitran-core/lib/Models';
import { withRouter } from 'react-router-dom';

const { TabPane } = Tabs;

class SignupModalClass extends React.Component {

    state = {
        activeKey: '1',
        loading: false,
        isUserDataValid: false,
        userFields: {},
        isPasswordValid: false,
        passwordFields: {},
        isAddressValid: false,
        addressFields: {},
        isCardValid: false,
        paymentFields: {}
    }

    async setAsyncState(state) {
        return new Promise((resolve) => this.setState(state, resolve));
    }

    canNextStep() {
        const { activeKey, isUserDataValid, isPasswordValid, isAddressValid, isCardValid } = this.state;
        switch (activeKey) {
            case '1':
                return isUserDataValid;
            case '2':
                return isPasswordValid;
            case '3':
                return isAddressValid;
            case '4':
                return isCardValid;
            default:
                return false;
        }
    }

    async saveInfo() {
        const { onSignuped, history } = this.props;
        const { userFields, passwordFields, addressFields, cardFields } = this.state;
        await this.setAsyncState({ loading: true });

        const userObj = User.fromObject({ ...userFields, ...passwordFields });
        const addressObj = Address.fromObject({
            ...addressFields,
            uf: addressFields.state || addressFields.uf
        });

        const registerUserResponse = await UserStore.register(userObj, addressObj);
        if (!registerUserResponse.success) {
            message.warning({
                content: registerUserResponse.error,
                key: 'signupError',
                duration: 1,
                onClose: async () => await this.setAsyncState({ loading: false })
            });
        } else {
            const { id: userId, token } = registerUserResponse.data;

            UserStore.setUserToken(token);
            await CardStore.initCardStore(`${userId}`, `${token}`);
            await CardStore.addCard(cardFields);

            if (onSignuped) {
                onSignuped();
            } else {
                history.push('/home')
            }
        }
    }

    render() {
        const { visible, onClose } = this.props;
        const { activeKey, loading } = this.state;
        return (
            <Modal visible={visible} onCancel={onClose} centered style={{ backgroundColor: 'transparent' }} header={null} footer={null} bodyStyle={{ display: 'flex', flexDirection: 'column', background: 'linear-gradient(135deg, rgba(0,199,73,1) 0%, rgba(0,243,63,1) 100%)', borderRadius: 15, height: '90vh', overflow: 'auto' }}>
                <Row>
                    <PageHeader title={<span style={{ color: "white" }}>Cadastro</span>} style={{ paddingLeft: 0 }} />
                </Row>
                <Row gutter={0} align="stretch" style={{ alignItems: 'stretch', flex: 1 }}>
                    <Col xs={24} align="middle">
                        <Tabs className="customTabBarWhite" activeKey={activeKey} tabBarStyle={{ color: "white" }}>
                            <TabPane tab="Usuário" key="1" disabled={activeKey != "1"}>
                                <UserDataSection
                                    onValidateFields={(isValid, fields) => {
                                        this.setState({ isUserDataValid: isValid, userFields: fields })
                                    }}
                                />
                            </TabPane>
                            <TabPane tab="Senha" key="2" disabled={activeKey != "2"} >
                                <PasswordSection
                                    onValidateFields={(isValid, fields) => {
                                        this.setState({ isPasswordValid: isValid, passwordFields: fields })
                                    }}
                                />
                            </TabPane>
                            <TabPane tab="Endereço" key="3" disabled={activeKey != "3"} >
                                <AddressSection
                                    onValidateFields={(isValid, fields) => {
                                        this.setState({ isAddressValid: isValid, addressFields: fields })
                                    }}
                                />
                            </TabPane>
                            <TabPane tab="Pagamento" key="4" disabled={activeKey != "4"} >
                                <PaymentSection
                                    onValidateFields={(isValid, fields) => {
                                        this.setState({ isCardValid: isValid, cardFields: fields })
                                    }}
                                />
                            </TabPane>
                        </Tabs>
                    </Col>
                </Row>
                <Row style={{ alignItems: 'stretch', justifyContent: 'space-between', marginBottom: 15 }}>
                    <Button className={'button-secondary'} style={{ flex: 1, borderWidth: 0, marginRight: 10, borderRadius: 10, textAlign: 'left' }} disabled={activeKey == "1" || loading} onClick={() => this.setState({ activeKey: `${Math.max(+(activeKey) - 1, 1)}` })}>
                        <LeftOutlined /> Voltar
                    </Button>
                    <Button className={'button-secondary'} style={{ flex: 1, borderWidth: 0, marginLeft: 10, borderRadius: 10, textAlign: 'right' }} loading={loading} onClick={() => activeKey != "4" ? this.setState({ activeKey: `${Math.min(+(activeKey) + 1, 4)}` }) : this.saveInfo()} disabled={!this.canNextStep()}>
                        {activeKey == "4" ? "Cadastrar" : "Próximo"} <RightOutlined />
                    </Button>
                </Row>
                <Row>
                    <FAQBlock />
                </Row>
            </Modal>
        )
    }
}

export const SignupModal = withRouter(SignupModalClass);