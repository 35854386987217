import { Result } from "antd"
import { LoadingOutlined } from '@ant-design/icons';

export const LoadingOrChildren = ({ loading, loadingChildren, loadingText,  children }) => {
    if (loading) {
        return (
            loadingChildren || <Result
                style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
                icon={<LoadingOutlined spin style={{ color: "#00c749" }} />}
                subTitle={loadingText || "Buscando informações do veículo"}
            />
        )
    }
    return children
}