import React from 'react'
import { Row, Button, Checkbox } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { TermsModal } from '~/containers/menu/TermsModal';
import { PrivacyPolicyModal } from '~/containers/menu/PrivacyPolicyModal';
import { Currency } from '~/core/tools/Currency';

import '~/styles/input.css'

const spanStyle = { fontWeight: 'bold', fontSize: 12 };

export class ConfirmContainer extends React.Component {

    state = {
        acceptedTerms: false,
        showPrivacyPolicy: false,
        showTerms: false
    }

    renderDebits() {
        const { order } = this.props;
        const { debits } = order;

        return (
            <div style={{ marginBottom: '2em' }}>
                {
                    debits.map((debit) => {
                        return (
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flex: 1 }}>
                                    <span style={spanStyle}>{debit.description}</span>
                                    <span style={spanStyle}>{Currency.toPtBr(+debit.value)}</span>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    renderParcels() {
        const { order } = this.props;
        const { parcels } = order;

        return (
            <div style={{ marginBottom: '2em' }}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <span style={spanStyle}>{parcels.numeroParcelas}x </span>
                    <span style={spanStyle}>{Currency.toPtBr(+parcels.valorParcela)}</span>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <span style={spanStyle}>Valor parcelado</span>
                    <span style={spanStyle}>{Currency.toPtBr(+parcels.valorParcelado)}</span>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <span style={spanStyle}>Encargos e Taxa de serviço</span>
                    <span style={spanStyle}>{Currency.toPtBr(+parcels.taxas.interna)}</span>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <span style={spanStyle}>Juros do cartão</span>
                    <span style={spanStyle}>{Currency.toPtBr(+parcels.taxas.externa)}</span>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <span style={{ fontWeight: 'bold' }}>Valor total</span>
                    <span style={{ fontWeight: 'bold' }}>{Currency.toPtBr(+parcels.total)}</span>
                </div>
            </div>
        )
    }

    renderCard() {
        const { order } = this.props;
        const { card } = order;
        return (
            <div style={{ marginBottom: '2em' }}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    <span style={spanStyle}>NÚMERO </span>
                    <span style={{ fontSize: 12 }}>{card.number}</span>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    <span style={spanStyle}>TITULAR</span>
                    <span style={{ fontSize: 12 }}>{card.name}</span>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    <span style={spanStyle}>VALIDADE</span>
                    <span style={{ fontSize: 12 }}>{card.expireDate}</span>
                </div>
            </div>
        );
    }

    render() {
        const { onNext, onBack } = this.props;
        const { acceptedTerms, showPrivacyPolicy, showTerms } = this.state;
        return (
            <div
                style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    background: 'linear-gradient(135deg, rgba(0,199,73,1) 0%, rgba(0,243,63,1) 100%)',
                    borderTopLeftRadius: 25,
                    borderTopRightRadius: 25,
                    paddingLeft: 10,
                    paddingRight: 10,
                    paddingTop: 15,
                    boxShadow: '1px 1px 5px #ccc'
                }}
            >
                <Row style={{ flex: 1, paddingTop: 15, paddingLeft: 10, paddingRight: 10 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', color: 'white', width: '100%' }}>
                        <p style={{ border: 'none', borderBottomWidth: 1, borderBottomStyle: 'solid', borderColor: 'white', marginBottom: '2em' }}>RESUMO DA TRANSAÇÃO</p>
                        <p>DÉBITOS SELECIONADOS</p>
                        {this.renderDebits()}
                        <p>PAGAMENTO</p>
                        {this.renderParcels()}
                        <p>CARTÃO</p>
                        {this.renderCard()}
                        <div style={{ flex: 1, display: "flex", alignItems: 'flex-end' }}>
                            <p>
                                <Checkbox checked={acceptedTerms} onChange={(e) => this.setState({ acceptedTerms: e.target.checked })} />
                                <span style={{ marginLeft: 5 }}>Concordo com os <a style={{ color: 'white', fontWeight: 'bold' }} onClick={() => this.setState({ showTerms: true })}>termos de uso</a> e <a style={{ color: 'white', fontWeight: 'bold' }} onClick={() => this.setState({ showPrivacyPolicy: true })}>política de privacidade</a> do aplicativo</span>
                            </p>
                        </div>
                    </div>
                </Row>
                <Row style={{ justifyContent: 'space-between', padding: 10 }}>
                    <Button className={'button-secondary'} style={{ flex: 1, borderWidth: 0, marginRight: 10, borderRadius: 10, textAlign: 'left' }} onClick={onBack}><LeftOutlined /> Voltar</Button>
                    <Button className={'button-secondary'} disabled={!acceptedTerms} style={{ flex: 1, borderWidth: 0, marginLeft: 10, borderRadius: 10, textAlign: 'right' }} onClick={onNext}>Pagar <RightOutlined /></Button>
                </Row>
                <TermsModal visible={showTerms} onClose={() => this.setState({ showTerms: false })} />
                <PrivacyPolicyModal visible={showPrivacyPolicy} onClose={() => this.setState({ showPrivacyPolicy: false })} />
            </div>
        )
    }
}