import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Request } from './core/http/Request';
import Configs from './config.json'

import "./styles/index.css";

Request.baseUrl = Configs.SERVER_LOCATION;

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
