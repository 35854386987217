import React, { useState } from 'react'
import { Col, Row, Drawer, Grid, PageHeader } from 'antd';

import { UserStore } from '../store/UserStore';
import { Link, useHistory } from 'react-router-dom';
import { AboutModal } from './menu/AboutModal';

import '../styles/input.css'
import { HelpModal } from './start/HelpModal';
import { TermsModal } from './menu/TermsModal';
import { PrivacyPolicyModal } from './menu/PrivacyPolicyModal';
import { UserModal } from './menu/UserModal';
import { AddressModal } from './menu/AddressModal';
import { PaymentModal } from './menu/PaymentModal';
import { VehicleModal } from './menu/VehicleModal';


const { useBreakpoint } = Grid;

const MenuItem = ({ icon, text, action }) => {
    return (
        <div style={{ paddingTop: 10, paddingBottom: 10 }}>
            <a onClick={action}>
                <Row>
                    <Col>
                        <span style={{ color: 'white' }}>
                            {text}
                        </span>
                    </Col>
                </Row>
            </a>
        </div>
    );
}

export const MenuDrawer = ({ visible, onSelectRenavam, onClose }) => {
    const screens = useBreakpoint();
    const history = useHistory();
    const [showAbout, setShowAbout] = useState(false);
    const [showHelp, setShowHelp] = useState(false);
    const [showTerms, setShowTerms] = useState(false);
    const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
    const [showUser, setShowUser] = useState(false);
    const [showVehicle, setShowVehicle] = useState(false);
    const [showAddress, setShowAddress] = useState(false);
    const [showPayment, setShowPayment] = useState(false);

    let width = "30vw";

    const screensAtivacted = Object.fromEntries(Object.entries(screens).filter(screen => !!screen[1]));

    if (screensAtivacted.xxl) {
        width = "20vw"
    } else if (screensAtivacted.xl) {
        width = "20vw"
    } else if (screensAtivacted.lg) {
        width = "30vw"
    } else if (screensAtivacted.md) {
        width = "40vw"
    } else if (screensAtivacted.sm) {
        width = "50vw"
    } else {
        width = "80vw"
    }

    return (
        <Drawer
            title="Basic Drawer"
            placement="right"
            closable={true}
            onClose={onClose}
            visible={visible}
            headerStyle={{ display: 'none' }}
            width={width}
            bodyStyle={{ background: 'linear-gradient(135deg, rgba(0,199,73,1) 0%, rgba(0,243,63,1) 100%)', paddingTop: 30 }}
        >
            <PageHeader title={<span style={{ color: "white" }}>Menu</span>} style={{ paddingLeft: 0 }} />
            <MenuItem text="MEUS VEÍCULOS" action={() => setShowVehicle(true)} />
            <MenuItem text="DADOS PESSOAIS / ACESSO" action={() => setShowUser(true)} />
            <MenuItem text="ENDEREÇO" action={() => setShowAddress(true)} />
            <MenuItem text="PAGAMENTO" action={() => setShowPayment(true)} />
            <MenuItem text="AJUDA" action={() => setShowHelp(true)} />
            <MenuItem text="SOBRE O DIGITRAN" action={() => setShowAbout(true)} />
            <MenuItem text="TERMOS DE USO" action={() => setShowTerms(true)} />
            <MenuItem text="POLÍTICA DE PRIVACIDADE" action={() => setShowPrivacyPolicy(true)} />
            <MenuItem text="SAIR" action={() => {
                UserStore.clearStore();
                history.replace("/");
            }} />
            <VehicleModal
                visible={showVehicle}
                onSelectRenavam={(renavam) => {
                    setShowVehicle(false)
                    onSelectRenavam && onSelectRenavam(renavam)
                }}
                onClose={() => setShowVehicle(false)}
            />
            
            <UserModal visible={showUser} onClose={() => setShowUser(false)} />
            <AddressModal visible={showAddress} onClose={() => setShowAddress(false)} />
            <PaymentModal visible={showPayment} onClose={() => setShowPayment(false)} />
            <AboutModal visible={showAbout} onClose={() => setShowAbout(false)} />
            <HelpModal visible={showHelp} onClose={() => setShowHelp(false)} />
            <TermsModal visible={showTerms} onClose={() => setShowTerms(false)} />
            <PrivacyPolicyModal visible={showPrivacyPolicy} onClose={() => setShowPrivacyPolicy(false)} />
        </Drawer>
    )
}
